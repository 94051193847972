import React from 'react';

import {Wrapper, MainText, Link, SecondaryText} from './Fraud.style';
import {PURCHASING_CONTACT_NUMBER} from '../../constants/constants';

function FraudBody(props) {
  const {
    navToFraudAwareness,
    navToFraudWarning,
    navToSuppliersNotification,
  } = props;
  return (
    <Wrapper>
      <MainText bold={true}>
        Suppliers to JJ Food Service be especially aware of the below.
      </MainText>
      <MainText bold={true}>
        It has come to our attention that fraudsters are impersonating JJ Food
        Service Limited to obtain services in our name.
      </MainText>
      <MainText bold={true}>
        All transactions with JJ Food Service must be conducted through our
        official website www.jjfoodservice.com. and deliveries made to our
        official branches and not elsewhere.
      </MainText>
      <MainText bold={true}>
        We will not accept any liability for goods or services supplied to any
        other addresses not mentioned on our official websites.
      </MainText>
      <ul>
        <li>
          JJ Food Service will never order goods without a period of negotiation
          and a formal trading agreement would be issued requiring you to supply
          product details etc.
        </li>
        <li>
          JJ Food Service will never order goods without a period of negotiation
          and a formal trading agreement would be issued requiring you to supply
          product details etc.
        </li>
        <li>
          Never send goods if the address is different than our published
          distribution sites, unless you receive written instructions from your
          usual JJ contact.
        </li>
        <li>
          Do not dispatch until a formal confirmation of your order is sent,
          after using the recognised booking-in procedure, which will be given
          to you via the JJ Purchasing team.
        </li>
      </ul>
      <Link onClick={navToFraudAwareness}>
        Fraud Awareness - Helpful Advice
      </Link>
      <Link
        href={
          'https://jjwebcontent.blob.core.windows.net/pdfs/policies/JJ-Food-Service-Ltd-Supplying-Us-September-2020.pdf'
        }
      >
        JJ Food Service Limited - Supplying Us - T&amp;C
      </Link>
      <Link onClick={navToFraudWarning}>FWD Fraud Warning</Link>
      <Link
        href={
          'https://www.thegrocer.co.uk/companies/asda-berry-bros-iceland-and-jj-impersonated-by-fraudsters/232539.article'
        }
      >
        Asda, Berry, Bros, Iceland and JJ Impersonated by Fraudsters
      </Link>
      <Link onClick={navToSuppliersNotification}>
        Suppliers Notification – To Future and Existing Suppliers
      </Link>
      <SecondaryText bold={true}>
        If in any doubt please contact us straight way.
      </SecondaryText>
      <SecondaryText bold={true}>
        Contact: {PURCHASING_CONTACT_NUMBER}
      </SecondaryText>
    </Wrapper>
  );
}

export default FraudBody;
