import React from 'react';

import {
  Header,
  Container,
  SubHeader,
  MainText,
  BackButton,
} from './HelpPage.style';
import {
  PURCHASING_CONTACT_NUMBER,
  PURCHASING_EMAIL_ADDRESS,
} from '../../constants/constants';

const HelpPage = props => (
  <Container>
    <Header>Quick Reference for the System</Header>
    <SubHeader>What is this system about?</SubHeader>
    <MainText>
      JJ Food Service has put a system in place to allow our partners to confirm
      our purchase orders directly into our systems. This allows us to service
      our customer and industry better.
    </MainText>

    <SubHeader>Delivery Date</SubHeader>
    <MainText>
      In the purchase order we have requested a delivery on date base on our
      usage and what we have promised to our customers. We would request that
      you keep to the request date as much as possible. If this continues to
      become a problem we request that you speak with your Buyer account
      manager.
    </MainText>

    <SubHeader>Editing</SubHeader>
    <MainText>
      You will only be able to edit this purchase order up to 2 hours after
      confirmation
    </MainText>

    <SubHeader>Booking In</SubHeader>
    <MainText>
      First you will need to confirm the delivery. After which you will need to
      complete the booking in form. Once the form has been completed and the
      total quantity is provided, you will be able to select a timeslot for your
      delivery.
    </MainText>

    <SubHeader>Who to call or e-mail if got a problem?</SubHeader>
    <MainText>
      If you got problems with the system e-mail {PURCHASING_EMAIL_ADDRESS} or
      call {PURCHASING_CONTACT_NUMBER}
    </MainText>
    <BackButton onClick={() => props.navBack()}>Back</BackButton>
  </Container>
);

export default HelpPage;
