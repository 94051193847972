import {put, call, takeLatest} from 'redux-saga/effects';
import * as ActionTypes from '../actions/types.action';
import {
  bookingDetailsCall,
  bookingIdCall,
  getBookingAreasCall,
  getBookingUnitsCall,
  getBookingSlotsCall,
  updateBookingCall,
  addNewPoCall,
  addNewDeliveryCall,
  removePoCall,
  updateDeliveryCall,
} from '../api/booking.api';
import {ERRORS} from '../constants/constants';

function* getBookingId(purchId, purchPin, token) {
  return yield call(bookingIdCall, purchId, purchPin, token);
}

function* getBookingDetails(purchId, bookingId, token) {
  return yield call(bookingDetailsCall, purchId, bookingId, token);
}

function* doUpdateDelivery(params) {
  return yield call(updateDeliveryCall, params);
}

function* doUpdateBooking(params) {
  return yield call(updateBookingCall, params);
}

function* bookingDetails(action) {
  const {purchId, purchPin, token, bookingIdParam = null} = action;
  try {
    let bookingId = bookingIdParam
      ? bookingIdParam
      : yield* getBookingId(purchId, purchPin, token);
    const details = yield* getBookingDetails(purchId, bookingId, token);
    yield put({
      type: ActionTypes.BOOKING_DETAILS_SUCCESS,
      details,
    });
    yield put({
      type: ActionTypes.NAVIGATE_TO_ROUTE,
      nextRoute: `/deliveryPage`,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.TOGGLE_BOOKING_DETAILS_MODAL,
    });
    yield put({
      type: ActionTypes.BOOKING_DETAILS_FAILED,
      message: e.response?.data?.Error || ERRORS.BOOKING_DETAILS_ERROR,
    });
  }
}

function* refreshBookingDetails(action) {
  const {purchId, bookingIdParam, token} = action;
  try {
    const details = yield* getBookingDetails(purchId, bookingIdParam, token);
    yield put({
      type: ActionTypes.BOOKING_DETAILS_SUCCESS,
      details,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.BOOKING_DETAILS_FAILED,
      message: e.response?.data?.Error || ERRORS.BOOKING_DETAILS_ERROR,
    });
  }
}

function* bookingAreas(action) {
  const {purchId, token} = action;
  try {
    const areas = yield call(getBookingAreasCall, purchId, token);
    yield put({
      type: ActionTypes.BOOKING_AREAS_SUCCESS,
      areas,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.BOOKING_AREAS_FAILED,
      message: e.response?.data?.Error || ERRORS.BOOKING_AREAS_ERROR,
    });
    yield put({
      type: ActionTypes.TOGGLE_GETAREAS_FAILED_MODAL,
    });
  }
}

function* bookingUnits(action) {
  const {purchId, token} = action;
  try {
    const units = yield call(getBookingUnitsCall, purchId, token);
    if (units) {
      yield put({
        type: ActionTypes.BOOKING_UNITS_SUCCESS,
        units,
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.BOOKING_UNITS_FAILED,
      message: e.response?.data?.Error || ERRORS.BOOKING_UNITS_ERROR,
    });
    yield put({
      type: ActionTypes.TOGGLE_GETUNITS_FAILED_MODAL,
    });
  }
}

function* timeSlots(action) {
  const {purchId, bookingId, token, deliveryId} = action.params;
  try {
    yield* doUpdateDelivery(action.params);
    const slots = yield call(getBookingSlotsCall, purchId, deliveryId, token);
    if (slots) {
      yield put({
        type: ActionTypes.TIME_SLOTS_SUCCESS,
        slots,
      });
    }
    yield put({
      type: ActionTypes.REFRESH_BOOKING_DETAILS,
      purchId,
      bookingIdParam: bookingId,
      token,
    });
  } catch (e) {
    let err;
    if (e.Id === 'Exception') {
      err = 'No time slots available. Please contact purchasing.';
    }
    yield put({
      type: ActionTypes.TIME_SLOTS_FAILED,
      message: err || e.response?.data?.Error,
    });
  }
}

function* addPurchOrder(action) {
  const {
    purchId,
    purchIdFieldValue,
    purchPinFieldValue,
    bookingId,
    token,
  } = action;
  try {
    yield call(
      addNewPoCall,
      purchId,
      purchIdFieldValue,
      purchPinFieldValue,
      bookingId,
      token
    );
    yield put({
      type: ActionTypes.ADD_PO_SUCCESS,
    });
    yield put({
      type: ActionTypes.REFRESH_BOOKING_DETAILS,
      purchId,
      bookingIdParam: bookingId,
      token,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.ADD_PO_FAILED,
      message: e.response?.data?.Error || ERRORS.ADD_PURCHASE_ORDER_ERROR,
    });
  }
}

function* removePurchOrder(action) {
  const {
    purchId,
    purchIdFieldValue,
    purchPinFieldValue,
    bookingId,
    token,
  } = action;
  try {
    yield call(
      removePoCall,
      purchId,
      purchIdFieldValue,
      purchPinFieldValue,
      bookingId,
      token
    );
    yield put({
      type: ActionTypes.REMOVE_PO_SUCCESS,
    });
    yield put({
      type: ActionTypes.REFRESH_BOOKING_DETAILS,
      purchId,
      bookingIdParam: bookingId,
      token,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.REMOVE_PO_FAILED,
      message: e.response?.data?.Error || ERRORS.REMOVE_PURCHASE_ORDER_ERROR,
    });
  }
}

function* addDelivery(action) {
  const {bookingId, purchIdList, deliveryUnits, purchId, token} = action;
  try {
    yield call(
      addNewDeliveryCall,
      bookingId,
      purchIdList,
      deliveryUnits,
      purchId,
      token
    );
    yield put({
      type: ActionTypes.ADD_DELIVERY_SUCCESS,
    });
    yield put({
      type: ActionTypes.REFRESH_BOOKING_DETAILS,
      purchId,
      bookingIdParam: bookingId,
      token,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.ADD_DELIVERY_FAILED,
      message: e.response?.data?.Error || ERRORS.ADD_DELIVERY_ERROR,
    });
  }
}

function* updateBooking(action) {
  const {purchId, bookingId, values, token} = action;
  try {
    yield call(updateBookingCall, values, purchId, bookingId, token);
    yield put({
      type: ActionTypes.UPDATE_BOOKING_SUCCESS,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.UPDATE_BOOKING_FAILED,
      message: e.response?.data?.Error || ERRORS.UPDATE_BOOKING_ERROR,
    });
  }
}

function* updateDelivery(action) {
  try {
    const {purchId, bookingId, token} = action;
    yield* doUpdateDelivery(action.params);
    yield put({
      type: ActionTypes.UPDATE_DELIVERY_SUCCESS,
    });
    yield put({
      type: ActionTypes.REFRESH_BOOKING_DETAILS,
      purchId,
      bookingIdParam: bookingId,
      token,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.UPDATE_DELIVERY_FAILED,
      message: e.response?.data?.Error || ERRORS.UPDATE_DELIVERY_ERROR,
    });
  }
}

function* submitDelivery(action) {
  try {
    yield* doUpdateBooking(action.params);
    yield* doUpdateDelivery(action.params);
    yield put({
      type: ActionTypes.UPDATE_BOOKING_SUCCESS,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.UPDATE_BOOKING_FAILED,
      message: e.response?.data?.Error || ERRORS.UPDATE_DELIVERY_ERROR,
    });
  } finally {
    yield put({
      type: ActionTypes.TOGGLE_DELIVERY_UPDATE_MODAL,
    });
  }
}

export default function* bookingOrderYield() {
  yield takeLatest(ActionTypes.BOOKING_DETAILS_REQUESTED, bookingDetails);
  yield takeLatest(ActionTypes.REFRESH_BOOKING_DETAILS, refreshBookingDetails);
  yield takeLatest(ActionTypes.BOOKING_AREAS_REQUESTED, bookingAreas);
  yield takeLatest(ActionTypes.BOOKING_UNITS_REQUESTED, bookingUnits);
  yield takeLatest(ActionTypes.TIME_SLOTS_REQUESTED, timeSlots);
  yield takeLatest(ActionTypes.ADD_PO_REQUESTED, addPurchOrder);
  yield takeLatest(ActionTypes.REMOVE_PO_REQUESTED, removePurchOrder);
  yield takeLatest(ActionTypes.ADD_DELIVERY_REQUESTED, addDelivery);
  yield takeLatest(ActionTypes.UPDATE_BOOKING_REQUESTED, updateBooking);
  yield takeLatest(ActionTypes.UPDATE_DELIVERY_REQUESTED, updateDelivery);
  yield takeLatest(ActionTypes.SUBMIT_DELIVERY_REQUESTED, submitDelivery);
}
