import {sizes} from '../styles/components/media-queries';
import {createGlobalStyle} from 'styled-components';

export const GlobalStyled = createGlobalStyle`

  @font-face {
    font-family: 'Open Sans';
    src: url('https://fonts.googleapis.com/css?family=Open+Sans') format('embedded-opentype');
  }
  @font-face {
    font-family: 'icomoon';
    src: url('https://cdn.foodit.com/assets/jjf/icomoon.eot?lfec5q');
    src: url('https://cdn.foodit.com/assets/jjf/icomoon.eot?lfec5q#iefix') format('embedded-opentype'),
      url('https://cdn.foodit.com/assets/jjf/icomoon.ttf?lfec5q') format('truetype'),
      url('https://cdn.foodit.com/assets/jjf/icomoon.woff?lfec5q') format('woff'),
      url('https://cdn.foodit.com/assets/jjf/icomoon.svg?lfec5q#icomoon') format('svg');

    font-weight: normal;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    box-sizing: border-box;
  }
  
  a {
    text-decoration: none;
  }

  .ReactModal__Content {
    max-height: 100%;
  }

  @media (max-width: ${sizes.small}px){
    .ReactModal__Content {
      
    }
  }
  // CSS classes for MarkDown content
  .columns-2 {
    columns: 2;
    column-gap: 5rem;
  }
  .forceBreak { -webkit-column-break-after: always; break-after: column; }
  span.forceBreak { display: block; }
`;
