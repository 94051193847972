import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import App from './App';

const Root = ({store}) => {
  const [initialLoad, setInitialLoad] = useState(false);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App
          initialLoadProp={initialLoad}
          setInitialLoadProp={() => setInitialLoad(true)}
        />
      </BrowserRouter>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
