import styled from 'styled-components';
import {media} from '../../styles/media-queries';
import theme from '../../styles/theme';
import {FlexContainer, Row, Block} from '../../styles/generic.style';
import {Button3a, Button3b} from '../../styles/components/buttons';

export const Wrapper = styled(FlexContainer)`
  border: solid 3px ${theme.primaryColor};
  margin-top: 3rem;
  height: 40rem;
  ${media.small`
    display:flex;
    flex-direction: column;
    height: 75rem;
    position: relative;
    width: 100%;
    margin: 0 auto;
  `};
`;

export const CenterRow = styled(Row)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.small`
    width: 75%;
  `}
`;

export const CenterBlock = styled(Block)`
  margin: 0 8rem;
  ${media.small`
    width: 75%;
  `}
`;

export const Left = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 50%;
  flex-direction: row;
  width: 50%;
  height: 100%;
  background-color: ${theme.lightBlueBg};
  ${media.small`
    display: flex;
    width: 100%;
    height: 50%;
  `}
`;

export const Right = styled(Left)`
  background-color: ${theme.lightGreenBg};
  ${media.small`
    display: flex;
    width: 100%;
    height: 50%;
  `}
`;

export const Header = styled.h2`
  text-align: center;
  padding-bottom 2rem;
  padding-top: 2rem;
  color: red;
  border: solid 3px ${theme.tertiaryColor};
  width: 100%;
  font-weight: strong;
`;

export const ConfirmButton = styled(Button3b)`
  width: 15rem;
  position: relative;
  &:hover {
    svg {
      fill: ${p => p.theme.secondaryBgColor};
    }
  }
  ${media.medium`
    width: ${p => (p.fullWidth ? '100%' : '15rem')};
    height: auto;
    display:flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
  `};
}
`;

export const BookButton = styled(Button3a)`
  width: 15rem;
  position: relative;
  align-text: center;
  &:hover {
    svg {
      fill: ${p => p.theme.secondaryBgColor};
    }
  }
    ${media.medium`
    width: ${p => (p.fullWidth ? '100%' : '15rem')};
    height: auto;
    display:flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
  `};
}
`;
