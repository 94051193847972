import React from 'react';

import {
  Header,
  Container,
  Title,
  Grid,
  Row,
  Col,
  MainText,
  AddressHeader,
  AddressContainer,
} from './Fraud.style';
import FraudHelper from '../../helpers/fraud.helper';
import {SubHeaderCenter} from '../../styles/generic.style';

function SupplierNotification() {
  return (
    <Container>
      <Title>SUPPLIER NOTICE</Title>
      <SubHeaderCenter>NOVEMBER 2012</SubHeaderCenter>

      <Grid>
        <Row>
          <Col size={7}>
            <Header>To Future And Existing Suppliers</Header>
            <MainText>Dear Supplier,</MainText>
            <MainText>
              I would remind you that our correct company name which is also our
              trading name is: JJ Food Service Limited. Our registered and head
              office is at: 7 Solar Way, Innova Park, Enfield, Middlesex, EN3
              7XY.
            </MainText>
            <MainText>
              We will only accept deliveries if the correct name of our Company
              is quoted together with the correct delivery address of our branch
              as well as our purchase order number.
            </MainText>
            <MainText>
              I would also remind you that we will always send our purchase
              order electronically. We would never order by telephone nor would
              we collect unless you receive written instructions from your usual
              JJ contact.
            </MainText>
            <MainText>
              If you are using an outside haulier to deliver your goods, you
              must provide the correct information to the haulier including our
              purchase order number. All the information on the CMR document
              must be complete and correct. The delivery note and invoice must
              clearly state the CMR number and container number where
              appropriate.
            </MainText>
            <MainText>
              We reserve the right to refuse any delivery that is not
              accompanied by the correct documentation including our correct
              name, delivery address and purchase order number. Please note that
              failure to provide the correct documentation will inevitably lead
              to delays in processing your invoice and consequently the payment.
            </MainText>
            <MainText>
              I shall be grateful if you update your records and inform all
              relevant departments as well as your hauliers to use the correct
              details in future. Failure to do so may result in the loss of your
              products and we will not accept liability for your loss.
            </MainText>
            <MainText>
              Thank you for your assistance and I look forward to a long and
              successful business relationship
            </MainText>
            <MainText>Yours faithfully,</MainText>
            <MainText>Edward Lanham</MainText>
            <MainText>Chief Finance Officer</MainText>
          </Col>
          <Col size={3} padding={'0 0 0 3rem'}>
            <Header>Official Addresses</Header>
            {FraudHelper.renderBranches}
          </Col>
        </Row>
      </Grid>
    </Container>
  );
}

export default SupplierNotification;
