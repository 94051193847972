import {INITIAL_STATE} from './initial.state';
import {
  LOGIN_REQUESTED,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  TIME_SLOTS_REQUESTED,
  TIME_SLOTS_FAILED,
  TIME_SLOTS_SUCCESS,
  REMOVE_PO_SUCCESS,
  ADD_PO_SUCCESS,
  ADD_PO_FAILED,
  REMOVE_PO_FAILED,
  CONFIRM_ORDER_SUCCESS,
  CONFIRM_ORDER_FAILED,
  CONFIRM_ORDER_REQUESTED,
  ADD_PO_REQUESTED,
  UPDATE_BOOKING_REQUESTED,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAILED,
  REMOVE_PO_REQUESTED,
  UPDATE_DELIVERY_REQUESTED,
  UPDATE_DELIVERY_FAILED,
  UPDATE_DELIVERY_SUCCESS,
  BOOKING_DETAILS_REQUESTED,
  BOOKING_DETAILS_FAILED,
  BOOKING_DETAILS_SUCCESS,
  SUBMIT_DELIVERY_REQUESTED,
} from '../actions/types.action';

export default (state = INITIAL_STATE.loading, action) => {
  switch (action.type) {
    case LOGIN_REQUESTED:
      return Object.assign({}, state, {login: true});
    case LOGIN_FAILED:
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {login: false});

    case CONFIRM_ORDER_REQUESTED:
      return Object.assign({}, state, {confirmOrder: true});
    case CONFIRM_ORDER_FAILED:
    case CONFIRM_ORDER_SUCCESS:
      return Object.assign({}, state, {confirmOrder: false});

    case TIME_SLOTS_REQUESTED:
      return Object.assign({}, state, {timeSlots: true});
    case TIME_SLOTS_FAILED:
    case TIME_SLOTS_SUCCESS:
      return Object.assign({}, state, {timeSlots: false});

    case ADD_PO_REQUESTED:
      return Object.assign({}, state, {addPo: true});
    case ADD_PO_FAILED:
    case ADD_PO_SUCCESS:
      return Object.assign({}, state, {addPo: false});

    case REMOVE_PO_REQUESTED:
      return Object.assign({}, state, {removePo: true});
    case REMOVE_PO_SUCCESS:
    case REMOVE_PO_FAILED:
      return Object.assign({}, state, {removePo: false});

    case BOOKING_DETAILS_REQUESTED:
      return Object.assign({}, state, {bookingDetails: true});
    case BOOKING_DETAILS_FAILED:
    case BOOKING_DETAILS_SUCCESS:
      return Object.assign({}, state, {bookingDetails: false});

    case SUBMIT_DELIVERY_REQUESTED:
    case UPDATE_BOOKING_REQUESTED:
      return Object.assign({}, state, {updateBooking: true});
    case UPDATE_BOOKING_FAILED:
    case UPDATE_BOOKING_SUCCESS:
      return Object.assign({}, state, {updateBooking: false});

    case UPDATE_DELIVERY_REQUESTED:
      return Object.assign({}, state, {updateDelivery: true});
    case UPDATE_DELIVERY_FAILED:
    case UPDATE_DELIVERY_SUCCESS:
      return Object.assign({}, state, {updateDelivery: false});

    default:
      return state;
  }
};
