import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import AlertModal from '../../components/Modal/AlertModal';
import {getBookingDetails} from '../../actions/booking.action';
import {clearModal} from '../../actions/modal.action';
import {clearErrors} from '../../actions/errors.action';
import {errorStyle} from '../../components/Modal/Modal.config';
import {
  ERROR_TITLE,
  PURCHASING_CONTACT_NUMBER,
} from '../../constants/constants';
import {resetNextRoute} from '../../actions/nextRoute.action';
import {
  Wrapper,
  Left,
  Right,
  ConfirmButton,
  BookButton,
  CenterRow,
  CenterBlock,
  SpinnerContainer,
} from './VendorDashboard.style';
import {
  FlexContainer,
  SubHeaderCenterSm,
  MainHeader,
} from '../../styles/generic.style';
import Loading from '../../components/loading/Loading';

function VendorDashboard(props) {
  const {
    history,
    token,
    purchId,
    purchPin,
    JADConfirmedBy,
    getBookingDetails,
    nextRoute,
    resetNextRoute,
    errors,
    modal,
    clearModal,
    clearErrors,
    isLoading,
    tokenCheck,
  } = props;
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    nextRoute && resetNextRoute();
  }, [nextRoute, resetNextRoute]);

  useEffect(() => {
    if (nextRoute && redirect) history.push(nextRoute);
  }, [history, nextRoute, redirect]);

  useEffect(() => {
    if (errors && errors.bookingDetails) {
      setError(errors.bookingDetails);
    }
    clearErrors();
  }, [clearErrors, errors]);

  const onConfirmOrder = () => {
    tokenCheck(purchId, token);
    history.push('/orderpage');
  };

  const onBookDelivery = () => {
    if (JADConfirmedBy) {
      setRedirect(true);
      getBookingDetails(purchId, purchPin, token);
    }
  };

  const modalCallback = () => {
    clearModal();
  };

  const header = (
    <h3>
      {' '}
      'This portal gives you direct secure access to our order confirmation
      system and delivery booking in system. If you have any questions call our
      team on {PURCHASING_CONTACT_NUMBER}. Please select one of the options to
      continue';
    </h3>
  );
  const flexMargin = '6rem 0';

  const renderLoadingSpinner = <Loading isLight={false} />;

  const orderConfirmation = (
    <FlexContainer margin={flexMargin}>
      <CenterRow>
        <CenterBlock>
          <MainHeader>Confirm Order</MainHeader>
        </CenterBlock>
      </CenterRow>
      <CenterRow>
        <CenterBlock>
          <SubHeaderCenterSm>
            Use this system to confirm a purchase order (PO) that you have
            received.
          </SubHeaderCenterSm>
        </CenterBlock>
      </CenterRow>
      <CenterRow>
        <CenterBlock>
          <ConfirmButton disabled={isLoading} onClick={onConfirmOrder}>
            Confirm Order
          </ConfirmButton>
        </CenterBlock>
      </CenterRow>
    </FlexContainer>
  );

  const bookingDelivery = (
    <FlexContainer margin={flexMargin}>
      <CenterRow>
        <CenterBlock>
          <MainHeader>Book Delivery</MainHeader>
        </CenterBlock>
      </CenterRow>
      <CenterRow>
        <CenterBlock>
          <SubHeaderCenterSm>
            Use this system to book in or edit bookings. This can be done by our
            vendors or their transport companies.
          </SubHeaderCenterSm>
        </CenterBlock>
      </CenterRow>
      <CenterRow>
        <CenterBlock>
          <BookButton
            disabled={!JADConfirmedBy || isLoading}
            onClick={onBookDelivery}
          >
            {isLoading ? renderLoadingSpinner : 'Book Delivery'}
          </BookButton>
        </CenterBlock>
      </CenterRow>
    </FlexContainer>
  );

  const renderAlertModal = (
    <AlertModal
      showModal={
        modal && errors && !errors.tokenExpired && modal.bookingDetailsModal
      }
      customStyle={errorStyle}
      error={error}
      renderTitle={ERROR_TITLE}
      renderContent={error}
      callback={modalCallback}
    />
  );

  return (
    <div>
      {header}
      <Wrapper>
        <Left>{orderConfirmation}</Left>
        <Right>{bookingDelivery}</Right>
      </Wrapper>
      {renderAlertModal}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    purchStatus: state.purchaseOrder.PurchStatus,
    confirmedDeliveryDateJAD: state.purchaseOrder.JADConfirmedDateTime,
    JADConfirmedBy: state.purchaseOrder.JADConfirmedBy,
    errorMsg: state.purchaseOrder.errorMsg,
    deliveryDate: state.purchaseOrder.DeliveryDate,
    bookingDetails: state.booking.bookingDetails,
    nextRoute: state.nextRoute,
    errors: state.errors,
    isLoading: state.loading.bookingDetails,
    modal: state.modal,
  };
};

export default connect(mapStateToProps, {
  getBookingDetails,
  resetNextRoute,
  clearModal,
  clearErrors,
})(withRouter(VendorDashboard));
