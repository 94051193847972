import {INITIAL_STATE} from './initial.state';
import {
  CONFIRM_ORDER_SUCCESS,
  PURCHASE_ORDER_SUCCESS,
} from '../actions/types.action';

export default (state = INITIAL_STATE.purchOrder, action) => {
  switch (action.type) {
    case PURCHASE_ORDER_SUCCESS:
      return Object.assign({}, state, {...action.poData});
    case CONFIRM_ORDER_SUCCESS:
    default:
      return state;
  }
};
