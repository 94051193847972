export const INITIAL_STATE = {
  auth: {
    token: null,
    PurchPin: null,
    PurchId: null,
  },
  purchOrder: {
    Items: null,
    JADConfirmedBy: null,
    JADVendIP: null,
    DeliveryDate: null,
    JADConfirmedDateTime: null,
    ErrorMsg: null,
    JADUnderDeliveryDays: null,
    JADOverDeliveryDays: null,
    AllowEdit: null,
    InventLocationId: null,
    InventLocationAddress: null,
    PurchId: null,
    PurchStatus: null,
  },
  booking: {
    bookingDetails: null,
    bookingAreas: null,
    bookingUnits: null,
    timeSlots: null,
  },
  errors: null,
  loading: {
    login: false,
    confirmOrder: false,
    bookingDetails: false,
    bookingAreas: false,
    timeSlots: false,
    addPo: false,
    removePo: false,
    updateBooking: false,
    updateDelivery: false,
  },
  nextRoute: null,
  modal: null,
};
