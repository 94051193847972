import styled from 'styled-components';
import {media} from '../../styles/media-queries';
import theme from '../../styles/theme';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100rem;
  margin: 0 auto;
  flex-direction: column;
  padding-top: 6rem;
  padding-bottom: 4.4rem;
  ${media.medium`
    width: 100%;
    padding: 2rem;
    margin: 0;
  `};
  ${media.smaller`
    width: 100%;
     padding: 2rem 0;
     padding-bottom: 0;
  `};
`;

export const Logo = styled.img`
  width: 17rem;
  height: 2.8rem;
  ${media.smaller`
    margin-left: 2rem;
  `};
`;

export const CopyRight = styled.div`
  color: ${props => props.theme.tertiaryTextColor};
  font-size: 1.2rem;
  padding-top: 1rem;
  ${media.smaller`
    margin-left: 2rem;
  `};
`;

export const VersionText = styled.div`
  color: ${props => props.theme.secondaryTextColor};
  font-size: 1rem;
  ${media.smaller`
    margin-left: 2rem;
  `};
`;

export const Item = styled.a`
  cursor: pointer;
  margin-top: 2rem;
  font-size: 1.3rem;
`;
