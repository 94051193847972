import axios from 'axios';
import {getApiConfig} from '../config/configProvider';
import {store} from '../index';
import {ERRORS, HTTP_ERROR_CODES} from '../constants/constants';
import * as ActionTypes from '../actions/types.action';

export const axiosInstance = axios.create({
  baseURL: getApiConfig().apiUrl,
  timeout: 30000,
  headers: {'Content-Type': 'application/json'},
});

export const getHeader = token => ({
  'Content-Type': 'application/json',
  JJ_TOKEN: token,
});

axiosInstance.interceptors.request.use(async config => {
  const state = await store.getState();
  if (state.auth.token) {
    config.headers = {...config.headers, JJ_TOKEN: state.auth.token};
    return config;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.code === 'ECONNABORTED') {
      store.dispatch({
        type: ActionTypes.AXIOS_TIMEOUT,
        message: ERRORS.AXIOS_TIMEOUT_ERROR,
      });
      return Promise.reject(error);
    }
    if (error && error.response && error.response.data) {
      const {Error} = error.response.data;
      if (Error === HTTP_ERROR_CODES.TOKEN_EXPIRED) {
        store.dispatch({
          type: ActionTypes.TOKEN_EXPIRED,
          message: Error,
        });
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export const api = {
  tokenUrl: '/api/token/v1/TokenObject',
  purchOrderUrl: '/api/PurchaseOrder/v1/po',
  confirmOrderUrl: '/api/purchaseorder/v1/updatevend',
  bookingIdUrl: '/api/BookingIn/v1/id',
  bookingDetailsUrl: '/api/BookingIn/v1/booking',
  areasUrl: '/api/BookingInArea/v1/Areas',
  unitsUrl: '/api/BookingInUnit/v1/unit',
  timeSlotsUrl: '/api/BookingInSlots/v1/DeliveryId',
  addPoUrl: '/api/BookingIn/v1/order',
  removePoUrl: '/api/BookingIn/v1/remove',
  addDeliveryUrl: '/api/BookingIn/v1/adddelivery',
  updateBookingUrl: '/api/BookingIn/v1/update',
  updateDeliveryUrl: '/api/BookingIn/v1/updatedelivery',
};
