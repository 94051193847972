import * as ActionTypes from './types.action';

export const clearModal = () => ({
  type: ActionTypes.CLEAR_MODAL,
});

export const togglePurchasedItems = () => ({
  type: ActionTypes.TOGGLE_PURCHASED_ITEMS,
});

export const toggleTimeOutModal = () => ({
  type: ActionTypes.TOGGLE_TIMEOUT_MODAL,
});
