import React from 'react';

import {
  Container,
  Wrapper,
  Description,
  Left,
  Right,
  Item,
  PurchId,
  BlueButton,
  GreenButton,
} from './TopBar.style';
import {sizes} from '../../styles/media-queries';

function TopBar(props) {
  const {purchId, navToHome, doLogout, navToHelp, isIE} = props;

  const welcomeMessage =
    window.innerWidth < sizes.small
      ? 'Portal'
      : "Welcome to JJ's Vendor Portal";

  const helpMessage =
    window.innerWidth < sizes.small ? 'Help' : 'Click here for help';

  const renderLeft = (
    <Left>
      <Item onClick={!isIE ? navToHome : undefined}>{welcomeMessage}</Item>
    </Left>
  );

  const renderRight = (
    <Right>
      <Item onClick={!isIE ? navToHelp : undefined}>{helpMessage}</Item>
      {!!purchId && <BlueButton onClick={navToHome}>{purchId}</BlueButton>}
      {!!purchId && <GreenButton onClick={doLogout}>Logout</GreenButton>}
    </Right>
  );

  return (
    <Wrapper>
      <Container>
        {renderLeft}
        {renderRight}
      </Container>
    </Wrapper>
  );
}

export default TopBar;
