import * as ActionTypes from './types.action';

export const getTimeSlots = params => ({
  type: ActionTypes.TIME_SLOTS_REQUESTED,
  params,
});

export const clearTimeSlots = () => ({
  type: ActionTypes.CLEAR_TIME_SLOTS,
});

// export const updateAndGetSlots = (
//   bookingId,
//   purchIdList,
//   deliveryUnits,
//   currentSelectedTime,
//   token
// ) => ({
//   type: ActionTypes.UPDATE_DELIVERY_REQUESTED,
//   bookingId,
//   purchIdList,
//   deliveryUnits,

//   token,
// });
