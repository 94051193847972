import React from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

function ModalClass(props) {
  const {isOpen, onClose, customStyle} = props;
  return (
    <ReactModal
      style={customStyle}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc={true}
    >
      {props.children}
    </ReactModal>
  );
}

export default ModalClass;
