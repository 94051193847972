import React from 'react';

import FraudHelper from '../../helpers/fraud.helper';
import {
  Header,
  Container,
  Title,
  Grid,
  Row,
  Col,
  MainText,
} from './Fraud.style';

function FraudAwareness() {
  return (
    <Container>
      <Title>FRAUD AWARENESS</Title>
      <img
        src={'https://secure.jjfoodservice.com/vss/headpiece/images/pic01.jpg'}
      />
      <Grid>
        <Row>
          <Col size={7}>
            <Header>Business Identity Fraud</Header>
            <MainText>
              Over the past two years we have made a great deal of effort to
              make our suppliers aware of a crime which can best be described as
              ‘Business Identity Fraud’.
            </MainText>
            <MainText>
              Fraudsters purporting to represent our company, get in touch with
              existing or potential suppliers and place orders for a variety of
              products. They use mobile telephones and email addresses which are
              similar to our genuine email address. They either ask for the
              goods to be delivered to an address which is not connected with
              our company or they give the address of one of our branches, but
              when the goods are about to be delivered, or are in transit, they
              request a change of delivery address or they arrange for a
              collection from the supplier’s premises via a haulier. The goods
              are delivered to the criminals and of course disappear. We then
              receive an invoice and have the unpleasant task of explaining to
              the supplier that we neither ordered, nor received, the goods and
              that they are the victim of a crime.{' '}
            </MainText>
            <MainText>
              Most suppliers are now taking the necessary precautions to combat
              this fraud. However, the fraudsters are still operating and
              unfortunately some suppliers remain gullible.
            </MainText>
            <MainText>
              We will never ask you to deliver anywhere other than to one of our
              branches, which for your convenience are listed to the right.
              Without you having complied with our standard ordering procedures
              and thereafter obtained from our company a genuine proof of
              delivery (POD), under no circumstances will we be held responsible
              for any losses you may incur.{' '}
            </MainText>
            <MainText>
              Existing suppliers will know that we have a well-established and
              robust purchasing procedure. If you are in any doubt as to whether
              you have received a genuine order from us, please call our
              procurement department who will be only too pleased to assist you.
            </MainText>
            <MainText bold={true}>Protect Your Company</MainText>
            <MainText bold={true}>
              Help yourselves and our industry. Take simple steps to ensure that
              you have a genuine order. Pass this notice on to as many people as
              you can, so that they also become aware of the problem, especially
              if you are using third party companies to deliver.
            </MainText>
            <MainText bold={true}>
              Do not allow these criminals to steal from you.
            </MainText>
          </Col>
          <Col size={3} padding={'0 0 0 3rem'}>
            <Header>Official Addresses</Header>
            {FraudHelper.renderBranches}
          </Col>
        </Row>
      </Grid>
    </Container>
  );
}

export default FraudAwareness;
