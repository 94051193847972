import * as ActionTypes from './types.action';

export const confirmOrder = (
  purchId,
  purchPin,
  deliveryDate,
  token,
  values
) => ({
  type: ActionTypes.CONFIRM_ORDER_REQUESTED,
  purchId,
  purchPin,
  deliveryDate,
  token,
  values,
});
