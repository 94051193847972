import {INITIAL_STATE} from './initial.state';
import {
  LOGIN_SUCCESS,
  CLEAR_DETAILS,
  CONFIRM_ORDER_REQUESTED,
  SUBMIT_DELIVERY_REQUESTED,
} from '../actions/types.action';
import {GoogleAnalyticsHelper} from '../helpers/googleAnalytics.helper';

export default (state = INITIAL_STATE.booking, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      GoogleAnalyticsHelper.trackEvent(LOGIN_SUCCESS, {
        purchId: action.purchId,
      });
      return state;
    case CLEAR_DETAILS:
      GoogleAnalyticsHelper.trackEvent(CLEAR_DETAILS, {});
      return state;
    case CONFIRM_ORDER_REQUESTED:
      GoogleAnalyticsHelper.trackEvent(CONFIRM_ORDER_REQUESTED, {action});
      return state;
    case SUBMIT_DELIVERY_REQUESTED:
      GoogleAnalyticsHelper.trackEvent(SUBMIT_DELIVERY_REQUESTED, {
        deliveryId: action.params.type,
      });
      return state;
    default:
      return state;
  }
};
