import React, {useState, useEffect, useRef} from 'react';
import * as Yup from 'yup';
import {Formik, Form, ErrorMessage} from 'formik';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {successStyle, errorStyle} from '../../components/Modal/Modal.config';
import AlertModal from '../../components/Modal/AlertModal';
import {clearModal} from '../../actions/modal.action';
import BookingPageHelper from '../../helpers/bookingPage.helper';
import {
  updateDelivery,
  getBookingAreas,
  getBookingUnits,
  getBookingSlots,
  addNewDelivery,
  addNewPo,
  removePo,
  submitDelivery,
} from '../../actions/booking.action';
import {getTimeSlots} from '../../actions/timeSlots.action';
import theme from '../../styles/theme';
import {
  DATE_STRING_FORMAT,
  DEFAULT_DATE_FORMAT,
  IS_NUMBER,
  CONTACT_NUMBER,
  ERROR_TITLE,
  BOOKING_SUCCESS_TITLE,
  BOOKING_SUCCESS_MESSAGE,
  ERRORS,
  ADD_POS_DESCRIPTION,
  PLEASE_SELECT_A_TIME,
} from '../../constants/constants';
import {TextInput} from '../../components/Form/Field.form';
import Loading from '../../components/loading/Loading';
import {
  Row,
  FlexContainer,
  Field,
  Block,
  Text,
  MainHeader,
  Header,
  SubHeaderSm,
  SubHeader,
  DescriptiveText,
  Label,
  ErrorText,
} from '../../styles/generic.style';
import {
  StyledForm,
  Wrapper,
  Time,
  Container,
  FindTimeSlotButton,
  SubmitButton,
  ButtonContainer,
  SlotsContainer,
  LoadingWrapper,
  RemoveButton,
  AddButton,
} from './Delivery.style';
import {clearErrors} from '../../actions/errors.action';
import {scrollToRef} from '../../helpers/window.helper';

function DeliveryScreen({
  purchId,
  bookingId,
  formattedDeliveryAddress,
  requestedDeliveryDate,
  transportCompany,
  transportContactName,
  transportContactNum,
  transportEmail,
  history,
  token,
  bookingAreas,
  bookingUnits,
  timeSlots,
  getBookingAreas,
  getBookingUnits,
  timeSlotsIsLoading,
  bookingOrdersField,
  bookingDeliveryField,
  addNewDelivery,
  submitDelivery,
  addNewPo,
  removePo,
  errors,
  addPoIsLoading,
  updateBookingIsLoading,
  getTimeSlots,
  modal,
  clearErrors,
  clearModal,
}) {
  const [bookingAreasData, setBookingAreasData] = useState({});
  const [slots, setSlots] = useState();
  const [currentPos, setCurrentPos] = useState([]);
  const [vehicleReg, setVehicleReg] = useState();
  const [deliveryId, setDeliveryId] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [bookedTimeSlot, setBookedTimeSlot] = useState();
  const [qtyData, setQtyData] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [purchNumFieldValue, setPurchNumFieldValue] = useState('');
  const [purchNumFieldActive, setPurchNumFieldActive] = useState(false);
  const [pinNumFieldValue, setPinNumFieldValue] = useState('');
  const [pinNumFieldActive, setPinNumFieldActive] = useState(false);
  const [addPoError, setAddPoError] = useState(false);
  const [apiError, setApiError] = useState();
  const [showTimeSlot, setShowTimeSlot] = useState(false);
  const [timeSlotsError, setTimeSlotsError] = useState(false);
  const transportRef = useRef(null);

  useEffect(() => {
    const initNewDelivery = () => {
      const [purchIdList, deliveryUnits] = BookingPageHelper.newDeliveryBuilder(
        bookingOrdersField
      );
      addNewDelivery(purchId, bookingId, purchIdList, deliveryUnits, token);
    };
    getBookingAreas(purchId, token);
    getBookingUnits(purchId, token);
    if (!bookingDeliveryField.length && bookingOrdersField) {
      initNewDelivery();
    }
  }, [
    addNewDelivery,
    bookingDeliveryField.length,
    bookingId,
    bookingOrdersField,
    getBookingAreas,
    getBookingUnits,
    purchId,
    token,
  ]);

  useEffect(() => {
    bookingAreas && setBookingAreasData(bookingAreas);
  }, [bookingAreas]);

  useEffect(() => {
    if (bookingDeliveryField.length) {
      setVehicleReg(bookingDeliveryField[0].VehicleNumField);
      setDeliveryId(bookingDeliveryField[0].DeliveryIdField);
      setBookedTimeSlot(
        bookingDeliveryField[0].WorkCalendarDateLineRefRecIdField
      );
      bookingAreasData &&
        setQtyData(
          BookingPageHelper.initQuantityData(
            bookingAreasData,
            bookingDeliveryField[0].JADGRDeliveryUnits
          )
        );
    }
  }, [bookingDeliveryField.length, bookingAreasData, bookingDeliveryField]);

  useEffect(() => {
    let poList = BookingPageHelper.createPoList(purchId, bookingOrdersField);
    setCurrentPos(poList);
  }, [bookingOrdersField, bookingOrdersField.length, purchId]);

  useEffect(() => {
    setSlots(timeSlots);
  }, [timeSlots]);

  //TODO fix error not reseting properly for the remove po form
  useEffect(() => {
    if (currentPos && errors && errors.removePo) {
      let currentPoList = [...currentPos];
      Object.values(currentPoList).forEach(Field => {
        if (Field.isSubmitting) {
          Field.isSubmitting = false;
          Field.error = errors.removePo;
        }
        setCurrentPos(currentPoList);
      });
    }
    if (errors && errors.addPo) {
      setAddPoError(errors.addPo);
    }
    if (errors && errors.updateBooking) {
      setIsSubmitting(false);
      setApiError(errors.updateBooking);
    }
    if (errors && errors.timeSlots) {
      setTimeSlotsError(errors.timeSlots);
    }
    if (errors && errors.bookingAreas) {
      setApiError(errors.bookingAreas);
    }
    if (errors && errors.bookingUnits) {
      setApiError(errors.bookingUnits);
    }
    clearErrors();
  }, [clearErrors, currentPos, errors]);

  useEffect(() => {
    setPurchNumFieldValue('');
    setPinNumFieldValue('');
  }, [currentPos]);

  const handleGetTimeSlots = () => {
    if (bookingDeliveryField.length) {
      const [errorData, isError] = BookingPageHelper.qtyFormErrorChecker(
        qtyData
      );
      if (isError) {
        return setQtyData(errorData);
      }
      const deliveryUnits = BookingPageHelper.deliveryUnitsBuilder(
        qtyData,
        bookingAreasData
      );
      const deliveryOrders = BookingPageHelper.deliveryOrdersBuilder(
        currentPos
      );
      setSelectedTimeSlot('');
      timeSlotsError && setTimeSlotsError(false);
      const params = {
        purchId,
        bookingId,
        deliveryOrders,
        deliveryUnits,
        timeSlotId: bookedTimeSlot,
        deliveryId,
        token,
      };
      getTimeSlots(params);
      setShowTimeSlot(true);
    } else {
      //TODO
      //no delivery in the data
    }
  };

  const handleRemovePo = e => {
    const poNumFieldValue = e.target.name;
    let poPinFieldValue = '';
    if (poNumFieldValue === purchId) return;
    const currentPoList = [...currentPos];
    Object.values(currentPoList).forEach(Field => {
      if (Field.poNum === e.target.name) {
        poPinFieldValue = Field.poPin;
        Field.isSubmitting = true;
      }
    });
    setCurrentPos(currentPoList);
    removePo(purchId, poNumFieldValue, poPinFieldValue, bookingId, token);
  };

  const handleAddNewPo = () => {
    if (!pinNumFieldValue || !purchNumFieldValue) {
      setAddPoError('Enter PO and Pin');
      return;
    }
    setAddPoError(false);
    addNewPo(purchId, purchNumFieldValue, pinNumFieldValue, bookingId, token);
  };

  const handleSubmit = values => {
    if (!selectedTimeSlot) {
      return;
    }
    const params = {
      values,
      purchId,
      bookingId,
      deliveryUnits: BookingPageHelper.deliveryUnitsBuilder(qtyData),
      deliveryOrders: BookingPageHelper.deliveryOrdersBuilder(currentPos),
      deliveryId,
      vehicleReg: values.vehicleReg,
      timeSlotId: selectedTimeSlot,
      token,
    };
    setIsSubmitting(true);
    submitDelivery(params);
  };

  const modalCallback = () => {
    clearModal();
    if (apiError !== ERRORS.UPDATE_BOOKING_ERROR) history.push('/dashboard');
  };

  const submitButtonText =
    (updateBookingIsLoading && <Loading isLight={false} />) ||
    (bookedTimeSlot ? 'Update Delivery' : 'Book Delivery');

  const Divider = ({color}) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 1,
      }}
    />
  );

  const createHeader = text => (
    <Header padding="1rem">
      {text}
      <Divider color={theme.primaryColor} />
    </Header>
  );

  const createPoList = () => {
    const len = currentPos.length;
    return (
      currentPos &&
      currentPos.map(({poNum}, index) => {
        return (
          <span key={poNum}>
            {poNum}
            {index !== len - 1 ? ', ' : ''}
          </span>
        );
      })
    );
  };

  const formatBookedTime = () => {
    if (timeSlots) {
      let time = timeSlots.filter(slot => slot.RecId === bookedTimeSlot);
      if (time.length) {
        return `${time[0].FromTime}-${time[0].ToTime}`;
      }
    }
  };

  const renderLoadingSpinner = (
    <FlexContainer>
      <LoadingWrapper>
        <Row margin={'5rem'}>
          <Block>
            <Loading isLight={false} />
          </Block>
        </Row>
      </LoadingWrapper>
    </FlexContainer>
  );

  const renderDeliveryBookedHeader = (
    <FlexContainer margin={'0 0 3rem 0'}>
      <Header>
        DELIVERY DETAILS
        <Divider color={theme.primaryColor} />
      </Header>
      <Row>
        <Block>
          <SubHeaderSm color={theme.tertiaryColor}>
            YOUR DELIVERY IS ALREADY BOOKED
          </SubHeaderSm>
        </Block>
      </Row>
      <Row>
        <Block>
          <SubHeaderSm color={theme.tertiaryColor}>
            If you need to make any changes to the order, please update the form
            and click update delivery
          </SubHeaderSm>
        </Block>
      </Row>
      <Row>
        <Block size={1}>
          <SubHeaderSm>Booked Date</SubHeaderSm>
        </Block>
        <Block size={3.5}>
          <SubHeaderSm>
            {moment(requestedDeliveryDate).format(DATE_STRING_FORMAT)} at{' '}
            {formatBookedTime()}
          </SubHeaderSm>
        </Block>
      </Row>
      <Row>
        <Block size={1}>
          <SubHeaderSm>Purchase Orders</SubHeaderSm>
        </Block>
        <Block size={3.5}>
          <SubHeaderSm>{createPoList()}</SubHeaderSm>
        </Block>
      </Row>
    </FlexContainer>
  );

  const createPurchDetails = () => {
    const {bookingDetailsConstants} = BookingPageHelper;
    const renderFunction = command => {
      switch (command) {
        case bookingDetailsConstants.GET_BOOKING_ID:
          return bookingId;
        case bookingDetailsConstants.GET_DELIVERY_DATE:
          return `${moment(requestedDeliveryDate).format(DATE_STRING_FORMAT)}`;
        case bookingDetailsConstants.GET_DELIVERY_ADDRESS:
          return formattedDeliveryAddress;
        default:
          return null;
      }
    };
    return BookingPageHelper.bookingDetails.map((field, index) => {
      return (
        <Row key={index}>
          <Block size={1}>
            <SubHeaderSm>{field.name}</SubHeaderSm>
          </Block>
          <Block size={3.5}>{renderFunction(field.command)}</Block>
        </Row>
      );
    });
  };

  const renderPurchDetails = (
    <FlexContainer>
      {createHeader('ORDER DETAILS')}
      {createPurchDetails()}
    </FlexContainer>
  );

  const createTransportForm = BookingPageHelper.transportFormDetails.map(
    (field, index) => (
      <Row key={index}>
        <Block size={1.5}>
          <SubHeaderSm>{field.label}</SubHeaderSm>
        </Block>
        <Block size={3.5}>
          <TextInput name={field.name} />
        </Block>
        <Block size={2}>
          <ErrorText>
            <ErrorMessage name={field.name} />
          </ErrorText>
        </Block>
      </Row>
    )
  );

  const renderTransportDetails = (
    <Container>
      {createHeader('TRANSPORT DETAILS')}
      <DescriptiveText ref={transportRef}>
        Please provide full contact details for the transport company. The
        contact person/team should be available at the same time as the
        deliveries. (e.g. evening,weekends)
      </DescriptiveText>
      {createTransportForm}
    </Container>
  );

  const handlePurchNumChange = e => {
    setPurchNumFieldValue(e.target.value);
  };

  const handlePinNumChange = e => {
    setPinNumFieldValue(e.target.value);
  };

  const handleOnBlur = e => {
    e.target.name === 'purchNum'
      ? setPurchNumFieldActive(false)
      : setPinNumFieldActive(false);
  };

  const handleOnFocus = e => {
    e.target.name === 'purchNum'
      ? setPurchNumFieldActive(true)
      : setPinNumFieldActive(true);
  };

  const handleRemovePinChange = (e, index) => {
    let currentPoList = [...currentPos];
    currentPoList[index] = {...currentPos[index], poPin: e.target.value};
    setCurrentPos(currentPoList);
  };

  const handleRemovePinFocusChange = index => {
    let currentPoList = [...currentPos];
    let isActive = currentPoList[index].isActive;
    currentPoList[index] = {...currentPos[index], isActive: !isActive};
    setCurrentPos(currentPoList);
  };

  const renderAddPoForm = (
    <Row>
      <Block size={2}>
        <Label
          error={addPoError}
          isActive={purchNumFieldActive}
          hasText={purchNumFieldValue}
        >
          PO Number
        </Label>
        <Field
          type="text"
          name="purchNum"
          value={purchNumFieldValue}
          error={addPoError}
          isActive={purchNumFieldActive}
          onFocus={e => handleOnFocus(e)}
          onBlur={e => handleOnBlur(e)}
          onChange={e => handlePurchNumChange(e)}
        />
      </Block>
      <Block size={2}>
        <Label
          error={addPoError}
          isActive={pinNumFieldActive}
          hasText={pinNumFieldValue}
        >
          Pin Number
        </Label>
        <Field
          type="text"
          name="pin"
          value={pinNumFieldValue}
          error={addPoError}
          isActive={pinNumFieldActive}
          onFocus={e => handleOnFocus(e)}
          onBlur={e => handleOnBlur(e)}
          onChange={e => handlePinNumChange(e)}
        />
      </Block>
      <Block size={1.5}>
        <AddButton disabled={addPoIsLoading} onClick={() => handleAddNewPo()}>
          ADD
        </AddButton>
      </Block>
      <Block size={3.5}>
        <ErrorText color={theme.tertiaryColor}>{addPoError}</ErrorText>
      </Block>
    </Row>
  );

  const renderPoList =
    currentPos &&
    currentPos.map(({poNum, poPin, isActive, error, isSubmitting}, index) => {
      let isDisabled = purchId === poNum;
      return (
        <Row key={poNum}>
          <Block size={2}>
            <SubHeaderSm>{poNum}</SubHeaderSm>
          </Block>
          <Block size={2}>
            <Label error={error} isActive={isActive} hasText={poPin}>
              Pin Number
            </Label>
            <Field
              disabled={isDisabled}
              error={error}
              isActive={isActive}
              onFocus={() => handleRemovePinFocusChange(index)}
              onBlur={() => handleRemovePinFocusChange(index)}
              onChange={e => handleRemovePinChange(e, index)}
            />
          </Block>
          <Block size={1.5}>
            <RemoveButton
              type="button"
              name={poNum}
              disabled={isDisabled}
              onClick={e => handleRemovePo(e, poNum)}
            >
              REMOVE
            </RemoveButton>
          </Block>
          <Block size={3.5}>
            <ErrorText color={theme.tertiaryColor}>
              {(isSubmitting && <Loading isLight={false} />) ||
                (error && 'Invalid Pin Number')}
            </ErrorText>
          </Block>
        </Row>
      );
    });

  const renderAddPurchId = (
    <Container>
      {createHeader('PURCHASE ORDERS')}
      <DescriptiveText>{ADD_POS_DESCRIPTION}</DescriptiveText>
      {currentPos && renderPoList}
      <Row margin={'2rem'}>
        <Block size={3}>
          <SubHeaderSm>Add a new purchase order to the delivery</SubHeaderSm>
        </Block>
      </Row>
      {renderAddPoForm}
    </Container>
  );

  const createQuantityUnitSelecter =
    bookingUnits &&
    bookingUnits.map(({UnitOfMeasure, Symbol}) => {
      return (
        <option key={Symbol} value={Symbol}>
          {UnitOfMeasure}
        </option>
      );
    });

  const handleQtyOnChange = e => {
    let data = [...qtyData];
    if (e.target.value < 0) return;
    if (e.target.value.match(IS_NUMBER) || e.target.value === '') {
      data.forEach(area => {
        if (area.name === e.target.name) {
          area.qty = e.target.value;
          area.qtyError = false;
          area.unitError = false;
        }
      });
      setSlots(null);
      setSelectedTimeSlot(null);
      setQtyData(data);
    }
  };

  const handleUnitOnChange = e => {
    let data = [...qtyData];
    data.forEach(area => {
      if (area.name === e.target.name) {
        area.unit = e.target.value;
        if (e.target.value === 'None') {
          area.qty = '';
        }
        area.unitError = false;
        area.qtyError = false;
      }
    });
    setSlots(null);
    setSelectedTimeSlot(null);
    setQtyData(data);
  };

  const createQuantityRow = qtyData
    ? qtyData.map(key => {
        let {qty, unit, name, qtyError, unitError} = key;
        return (
          <Row key={name}>
            <Block size={1.5}>
              <SubHeaderSm>{name}</SubHeaderSm>
            </Block>
            <Block size={0.2}>
              <Field
                name={name}
                type="text"
                halfWidth={true}
                value={qty}
                error={qtyError}
                onChange={e => handleQtyOnChange(e)}
              />
            </Block>
            <Block size={3}>
              <Field
                as="select"
                name={name}
                value={unit}
                error={unitError}
                onChange={e => handleUnitOnChange(e)}
              >
                <option>None</option>
                {bookingUnits && createQuantityUnitSelecter}
              </Field>
            </Block>
            <Block size={3} />
          </Row>
        );
      })
    : renderLoadingSpinner;

  const renderSlotsError = (
    <FlexContainer>
      <Row margin={'1rem'}>
        <ErrorText color={theme.tertiaryColor}>{timeSlotsError}</ErrorText>
      </Row>
    </FlexContainer>
  );

  const onTimeChange = e => {
    let selectedTime =
      e.target.value === PLEASE_SELECT_A_TIME ? '' : e.target.value;
    setSelectedTimeSlot(selectedTime);
  };

  const renderTimesRange = slots && (
    <Time value={selectedTimeSlot} onChange={e => onTimeChange(e)}>
      <option key={'default'} value={null}>
        {PLEASE_SELECT_A_TIME}
      </option>
      {slots.map((slotTime, index) => {
        return (
          <option key={index} value={slotTime.RecId}>
            {slotTime.FromTime} - {slotTime.ToTime}
          </option>
        );
      })}
    </Time>
  );

  const renderTimeSlots = () => {
    if (timeSlotsIsLoading) return renderLoadingSpinner;
    if (timeSlotsError) return renderSlotsError;
    if (slots && showTimeSlot)
      return (
        <SlotsContainer>
          <Row>
            <Block>
              <SubHeader>SELECT A TIME SLOT FOR THE DELIVERY</SubHeader>
            </Block>
          </Row>
          <Row margin={'2rem'}>
            <Block size={1}>
              <SubHeaderSm>Date:</SubHeaderSm>
            </Block>
            <Block size={6}>
              <SubHeaderSm>
                {moment(requestedDeliveryDate).format(DEFAULT_DATE_FORMAT)}
              </SubHeaderSm>
            </Block>
          </Row>
          <Row>
            <Block size={1}>
              <SubHeaderSm>Time:</SubHeaderSm>
            </Block>
            <Block size={4}>{renderTimesRange}</Block>
            <Block size={2} />
          </Row>
        </SlotsContainer>
      );
  };

  const renderQuantityAndSlots = (
    <Container>
      {createHeader('CONFIRM QUANTITY')}
      {createQuantityRow}
      <FindTimeSlotButton
        type="button"
        disabled={BookingPageHelper.disableSlotsButton(qtyData)}
        color={theme.primaryColor}
        onClick={() => handleGetTimeSlots()}
      >
        FIND TIME SLOT
      </FindTimeSlotButton>
      {qtyData && renderTimeSlots()}
    </Container>
  );

  const renderModalHelper = () => {
    if (errors && errors.tokenExpired) return;
    return (
      (modal && modal.apiCallFailedModal) || (modal && modal.updateBookingModal)
    );
  };

  const renderAlertModal = (
    <AlertModal
      showModal={renderModalHelper()}
      customStyle={apiError ? errorStyle : successStyle}
      error={apiError}
      renderTitle={apiError ? ERROR_TITLE : BOOKING_SUCCESS_TITLE}
      renderContent={apiError || BOOKING_SUCCESS_MESSAGE}
      callback={modalCallback}
    />
  );

  const renderDeliveryForm = (
    <Formik
      isSubmitting={false}
      initialValues={BookingPageHelper.createFormInitialValues(
        transportCompany,
        transportContactName,
        transportContactNum,
        transportEmail,
        vehicleReg,
        bookingAreasData,
        bookingDeliveryField
      )}
      validationSchema={Yup.object({
        transportEmail: Yup.string()
          .email('Must be a valid Email')
          .required('Required'),
        transportCompany: Yup.string().required('Required'),
        transportContactName: Yup.string().required('Required'),
        transportContactNum: Yup.string('Required')
          .matches(CONTACT_NUMBER, 'Must be a valid number')
          .required('Required'),
        vehicleReg: Yup.string().required('Required'),
      })}
      onSubmit={(values, {setSubmitting}) => {
        setSubmitting(false);
        handleSubmit(values);
      }}
    >
      {({validateForm}) => (
        <StyledForm>
          <Form>
            {!!bookedTimeSlot && renderDeliveryBookedHeader}
            {renderPurchDetails}
            {renderTransportDetails}
            {renderAddPurchId}
            {renderQuantityAndSlots}
            <ButtonContainer>
              <SubmitButton
                type="submit"
                disabled={isSubmitting || !selectedTimeSlot}
                color={theme.secondaryColor}
                onClick={() =>
                  validateForm().then(errors => {
                    if (Object.keys(errors).length > 0) {
                      scrollToRef(transportRef);
                    }
                  })
                }
              >
                {submitButtonText}
              </SubmitButton>
            </ButtonContainer>
          </Form>
        </StyledForm>
      )}
    </Formik>
  );

  return (
    <Wrapper>
      <MainHeader padding={'3rem 0'}>BOOKING FORM</MainHeader>
      {deliveryId && qtyData ? renderDeliveryForm : renderLoadingSpinner}
      {renderAlertModal}
    </Wrapper>
  );
}

const mapStateToProps = ({purchaseOrder, booking, loading, errors, modal}) => {
  return {
    purchId: purchaseOrder.PurchId,
    bookingId: booking.bookingDetails.BookingId,
    confirmedDeliveryDate: purchaseOrder.confirmedDeliveryDate,
    confirmedDeliveryTime: purchaseOrder.confirmedDeliveryTime,
    currentPoList: booking.poList,
    bookingOrders: booking.bookingDetails.JADGRBookingOrdersField,
    bookingUnits: booking.bookingUnits,
    timeSlots: booking.timeSlots,

    bookingAreas: booking.bookingAreas,
    bookingOrdersField: booking.bookingDetails.JADGRBookingOrdersField,
    bookingDeliveryField: booking.bookingDetails.JADGRDeliveryField,

    JAD_PurchLineContractList: purchaseOrder.JAD_PurchLineContractList,
    formattedDeliveryAddress: purchaseOrder.InventLocationAddress,
    requestedDeliveryDate: purchaseOrder.DeliveryDate,
    contactName: purchaseOrder.contactName,
    emailAddress: purchaseOrder.emailAddress,

    transportCompany: booking.bookingDetails.TransportCompanyName,
    transportContactName: booking.bookingDetails.TransportCompanyContactName,
    transportContactNum: booking.bookingDetails.TransportCompanyContactNumber,
    transportEmail: booking.bookingDetails.TransportCompanyContactEmail,

    timeSlotsIsLoading: loading.timeSlots,
    addPoIsLoading: loading.addPo,
    updateBookingIsLoading: loading.updateBooking,
    updateDeliveryIsLoading: loading.updateDelivery,
    errors: errors,
    modal: modal,
  };
};

export default connect(mapStateToProps, {
  updateDelivery,
  getBookingAreas,
  getBookingUnits,
  getBookingSlots,
  addNewDelivery,
  addNewPo,
  removePo,
  getTimeSlots,
  submitDelivery,
  clearErrors,
  clearModal,
})(withRouter(DeliveryScreen));
