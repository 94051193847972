import styled from 'styled-components';
import {media} from '../../styles/media-queries';
import {
  SubmitButton3a,
  Button3b,
  Button3c,
} from '../../styles/components/buttons';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 0rem;
  height: 68rem;
  position: relative;
  z-index: 10;
  border: solid 3px #3594cc;
  margin-top: 0rem;
  ${media.small`
    height: 65rem;
    position: relative;
    width: 90%;
    margin: 0 auto;
  `};
  ${media.smaller`
    height: 85rem;
    position: relative;
    width: 90%;
    margin: 0 auto;
  `};
`;

export const StyledForm = styled.div`
  margin-top: 4rem;
  width: 70%;
  ${media.small`
    width: 90%;
  `};
  ${media.smaller`
    text-align: center;
`}
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 3rem 1rem;
  ${media.smaller`
    flex-direction: column;
  `};
`;

export const Center = styled(Left)`
  justify-content: center;
  margin-top: 50px;
`;

export const OrderConfirmedMessage = styled.div`
  padding: 1.5rem 0.7rem;
  font-style: italic;
  color: red;
  font-size: 1.75rem;
`;

export const ConfirmButton = styled(SubmitButton3a)`
  width: 19rem;
  text-align: center;
  margin-top: 3rem;
  ${media.smaller`
    width: ${p => (p.fullWidth ? '100%' : '15rem')};
  `};
  ${media.smallest`
    text-align: center;
    height: auto;
  `};
}
`;

export const BackButton = styled(Button3c)`
  width: 19rem;
  text-align: center;
  margin-top: 3rem;
  &:hover {
    svg {
      fill: ${p => p.theme.secondaryBgColor};
    }
  }
${media.smaller`
  width: ${p => (p.fullWidth ? '100%' : '15rem')};
`};
${media.smallest`
  text-align: center;
  height: auto;
`};
}
`;

export const ItemsButton = styled(Button3b)`
  width: 12rem;
  height: 3rem;
  margin-left: 0.5rem;
  align-items: center;
  text-align: center;
  &:hover {
    svg {
      fill: ${p => p.theme.secondaryBgColor};
    }
  }
  ${media.smaller`
    width: ${p => (p.fullWidth ? '100%' : '15rem')};
  `};
  ${media.smallest`
    text-align: center;
    height: auto;
  `};
`;
