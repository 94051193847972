import {getApiConfig} from '../config/configProvider';
import {axiosInstance, api} from './api.config';
import config from '../config/config.json';

console.log('env - ', config.deploymentEnv);
console.log('version - ', config.version);

const optionAxios = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const getTokenApi = () => {
  return axiosInstance
    .post(api.tokenUrl, {v: 'v1'})
    .then(res => res.data?.Data?.TOKEN);
};

export const getPurchaseOrder = (PurchId, PurchPin, token) => {
  const params = {
    PurchId,
    PurchPin,
    token,
    type: 'json',
    version: 'V1',
  };
  return axiosInstance
    .get(api.purchOrderUrl, {params})
    .then(res => res.data?.Data);
};

export const checkTokenCall = (purchId, token) => {
  const params = {
    purchId,
    token,
    type: 'json',
    version: 'V1',
  };
  return axiosInstance.get(api.areasUrl, {params});
};
