import styled from 'styled-components';

import {media} from '../../styles/media-queries';
import {Dropdown} from '../../styles/components/dropdown';
import theme from '../../styles/theme';
import {FormInput} from '../../styles/components/input';
import {
  SubmitButton3a,
  SubmitButton3b,
  Button3c,
} from '../../styles/components/buttons';
import {FlexContainer} from '../../styles/generic.style';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 0rem;
  height: fit-content;
  position: relative;
  z-index: 10;
  border: solid 3px ${theme.primaryColor};
  margin-top: 0rem;
  ${media.small`
    width: 100%;
    margin: 0 auto;
  `};
  ${media.smaller`
    width: 100%;
    margin: 0 auto;
    
  `};
`;

export const Content = styled.div`
  width: 70%;
  display: flex;
  background-color: ${p => p.theme.tertiaryBgColor};
  justify-content: center;
  align-items: center;
  min-height: ${p => {
    return !p.confirmed ? '5rem' : '25rem';
  }};
  border: solid 0.1rem ${theme.dimmerBlack};
  background-color: ${theme.quaternaryBgColor};
  margin-top: 5rem;
  ${media.medium`
    flex-direction: column;
    min-height: 30rem
  `};
  ${media.smaller`
    flex-direction: column;
    min-height: 30rem
  `};
`;

export const DeliveryConfirmationWrapper = styled(FlexContainer)`
  border: 2px solid red;
`;

export const BookedTimeContainer = styled(FlexContainer)`
  width: 55%;
  justify-content: center;
`;

export const Row = styled.div`
  width: 100%;
  margin: auto 0;
  margin-top: ${p => p.margin || '2rem'};
  justify-content: center;
  text-align: center;
  ${media.smaller`
    margin: 2rem 0;
  `};
`;

export const Block = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  ${media.smallest`
    width: 100%;
  `};
`;

export const Container = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 2rem 0rem;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-basis: 100%;
  padding: 0.7rem;
  margin-top: ${p => p.margin};
  ${media.smallest`
    width: 100%;
  `};
`;

export const DetailsBlock = styled.div`
  flex: ${p => p.size || 1};
  margin: 0.5rem;
  align-self: center;
`;

export const DetailsText = styled.div`
  font-size: 1.3rem;
`;

export const LoadingWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
`;

export const StyledForm = styled.div`
  margin-bottom: 4rem;
  width: 70%;
  ${media.smaller`
  width: 90%;
  justify-content: center;
  text-align: center;
  `}
`;

export const QtyRow = styled.div`
  display: flex;
  margin: 1rem;
  ${media.small`
    display: block;
  `};
  ${media.smaller`
    margin: 2rem 0;
  `};
`;

export const SlotsContainer = styled(Container)`
  border: solid 1px ${theme.primaryColor};
  background-color: ${theme.lightBlueBg};
  min-height: 22rem;
  padding: 3rem;
  width: 100%;
  margin-top: 3rem;
`;

export const Time = styled(Dropdown)`
  color: ${p => p.theme.tertiaryTextColor};
  width: 96%;
  position: relative;
  z-index: 2;
  border: solid 0.1rem ${p => p.theme.borderColor};
  background-color: ${p => p.theme.tertiaryBgColor};
`;

export const MainHeader = styled.h1`
  width: 100%;
  padding-top: 3rem;
  text-align: Center;
  color: ${theme.darkBlue};
`;

export const Header = styled.h2`
  text-align: Left;
  padding-bottom: ${p => (p.padding ? p.padding : '0rem')};
  width: 100%;
  color: ${theme.darkBlue};
`;

export const SubHeader = styled.h2`
  margin: 0rem;
  justify-content: flex-start;
  color: ${theme.darkBlue};
`;

export const SubHeaderCenter = styled.h2`
  padding: 6px;
  margin-top: 0rem;
  justify-content: center;
  color: ${theme.darkBlue};
`;

export const SubHeaderSm = styled.h2`
  font-size: 1.5rem;
  color: ${p => p.color || p.theme.darkBlue};
`;

export const DescriptiveText = styled.h3`
  padding: 0 1rem 3rem 0rem;
  margin-top: 0rem;
  align-items: center;
  text-align: left;
`;

export const InputLabel = styled.div`
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  min-height: 3rem;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Right = styled(Left)`
  justify-content: flex-end;
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Tab = styled.div`
  justify-content: flex;
  width: 30%;
  padding: 1rem 3rem;
  font-size: 1.8rem;
  cursor: pointer;
  outline: none;
  background-color: ${p =>
    p.isActive ? p.theme.secondaryColor : p.theme.primaryColorHover};
  ${media.small`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.4rem;
  `};
`;

export const AddPoBlock = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  height: ${p => `${p.height}rem`};
  z-index: 10;
`;

export const PoBlockLeft = styled.div`
  display: block;
  flex: 50%;
  align-items: flex-start;
  margin-left: 0rem;
  height: ${p => `${p.height - 0.5}rem`};
  background-color: #ffffff;

  ${media.medium`
    margin: 0 auto;
    padding-right: 5rem;
  `}
  ${media.small`
    display: flex;
    width: 100%;
    height: 50%;
  `}
`;

export const PoBlockRight = styled(PoBlockLeft)`
  align-items: flex-start;
  alignflex: 50%;
  padding: 0rem;
  ${media.medium`
    margin: 0 auto;
    padding-right: 0rem;
    padding-left: 5rem;
  `}
  ${media.small`
     flex-direction: column;
     padding-bottom: 3rem;
  `}
`;

export const InputField = styled(FormInput)`
  min-width: 5.2rem;
  &&&& {
    width: 40%;
    padding-right: 0;
  }
`;

export const PoDisplay = styled.h2`
  align-items: center;
  jusitfy-content; center;
  margin-bottom: 0;
  color: ${theme.darkBlue};
`;

export const PoContainer = styled.ul`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0rem 1rem 1rem 0rem;
`;

export const FindTimeSlotButton = styled(SubmitButton3b)`
  width: 36rem;
  padding: 1rem;
  margin-top: 25px;
  &:hover {
    svg {
      fill: ${p => p.theme.secondaryBgColor};
    }
  }
  ${media.medium`
    width: ${p => (p.fullWidth ? '100%' : '28rem')};
  `};
  ${media.small`
    width: ${p => (p.fullWidth ? '100%' : '20rem')};
  `};
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%
  margin-top: 3rem;
  flex-direction: column;
  justify-content: flex-end;
`;

export const SubmitButton = styled(SubmitButton3a)`
  width: 36rem;
  text-align: center;
  padding: 2rem;
  ${media.medium`
  width: ${p => (p.fullWidth ? '100%' : '28rem')};
`};
  ${media.small`
  width: ${p => (p.fullWidth ? '100%' : '20rem')};
`};
`;

export const RemoveButton = styled(Button3c)`
  font-size: 1rem;
  width: 6rem;
  min-width: 2rem;
  height: 2.5rem;
  min-height: 2.5rem;
  margin-left: 3rem;
  jusitfy-content; flex-start;
`;

export const AddButton = styled(RemoveButton)`
  color: ${p =>
    p.disabled ? p.theme.disabledColor : p.theme.primaryTextColor};
  background-color: ${p => (p.disabled ? 'transparent' : p.theme.primaryColor)};
  border-color: ${p =>
    p.disabled ? p.theme.disabledColor : p.theme.primaryColor};
  &:hover {
    background-color: ${p =>
      p.disabled ? 'transparent' : p.theme.primaryColorHover};
    border-color: ${p =>
      p.disabled ? p.theme.disabledColor : p.theme.primaryColorHover};
  }
`;

export const Button = styled.button.attrs(p => ({
  type: p.type,
  className: `ui button`,
}))`
  &&& {
    font-size: ${p => p.fontSize || '1.5rem'};
    border: ${p => p.border || 'solid 0.2rem'};
    margin-top: ${p => p.marginTop || '1rem'};
    background: transparent none;
    border-radius: 0.3rem;
    text-transform: none;
    text-shadow: none;
    box-shadow: 0 0 0 1px ${theme.buttonShadow} inset;
    color: ${p => p.color};
  }
}
`;

export const PoField = styled(FormInput)`
  margin: 0.4rem;
  min-width: 8rem;
  border-color: ${p => {
    if (p.error) return p.theme.tertiaryColor;
    if (p.isActive && !p.error) return p.theme.primaryColor;
  }}
  border-style: solid;
`;

export const Label = styled.div`
  display: inline-block;
  position: absolute;
  pointer-events: none;
  transition: all 100ms ease-in;
  font-size: ${p => (p.isActive || p.hasText ? '1.1rem' : '1.5rem')};
  transform: translateY(${p => (p.isActive || p.hasText ? '-6px' : '15px')});
  padding: 0px 5px;
  margin-left: 10px;
  background: ${p => {
    if (p.isActive || p.hasText) return theme.primaryTextColor;
  }};
  color: ${p => {
    if (p.isActive && !p.error) return p.theme.primaryColor;
    else if ((p.error && p.isActive) || (p.error && p.hasText))
      return p.theme.tertiaryColor;
    else return p.theme.secondaryTextColor;
  }};
  ${media.medium`
  font-size: 1.4rem;
`};
  ${media.small`
  font-size: 1.3rem;
`};
`;

export const TestField = styled.input`
  color: ${p => p.theme.textColor};
  margin: 0.4rem;
  border-color: red;
  border-style: solid;
`;
