import React from 'react';
import logo from '../../assets/logo.png';
import config from '../../config/config.json';
import {Wrapper, Logo, CopyRight, VersionText, Item} from './Footer.style';
import {Text} from '../../styles/generic.style';

function Footer() {
  return (
    <Wrapper>
      <Logo src={logo} />
      <Item href={'https://www.jjfoodservice.com/'}>www.jjfoodservice.com</Item>
      <CopyRight>&#169; JJ Foodservice. All Right Reserved.</CopyRight>
      <VersionText>Version - {config.version}</VersionText>
    </Wrapper>
  );
}

export default Footer;
