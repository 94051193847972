import React, {useCallback, useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {Route, Switch, Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {ThemeProvider} from 'styled-components';

import {clearDetailsDispatch, tokenCheck} from '../actions/auth.action';
import {clearModal, toggleTimeOutModal} from '../actions/modal.action';
import {clearErrors} from '../actions/errors.action';
import {resetNextRoute} from '../actions/nextRoute.action';
import TopBar from '../components/TopBar/TopBar';
import Header from '../components/Header/Header';
import ConfirmOrder from './ConfirmOrderPage/ConfirmOrder';
import DeliveryScreen from './BookingPage/DeliveryScreen';
import VendorDashboard from './DashboardPage/VendorDashboard';
import Footer from '../components/Footer/Footer';
import LoginForm from './LoginPage/Login';
import {NotFound} from '../components/NotFound/NotFound';
import theme from '../styles/theme';
import {Container, StyledApp, Wrapper} from './App.style';
import {GlobalStyled} from '../styles/global.style';
import SessionExpired from './SessionExpiredPage/SessionExpired';
import AlertModal from '../components/Modal/AlertModal';
import {errorStyle} from '../components/Modal/Modal.config';
import {ERROR_TITLE} from '../constants/constants';
import HelpPage from '../components/Help/HelpPage';
import FraudAwareness from '../components/Fraud/FraudAwareness';
import FraudWarning from '../components/Fraud/FraudWarning';
import SupplierNotification from '../components/Fraud/SupplierNotification';
import {GoogleAnalyticsHelper} from '../helpers/googleAnalytics.helper';
import {isBrowserIE} from '../helpers/detectBrowser.helper';
import {BrowserNotSupported} from './BrowserNotSupportedPage/BrowserNotSupported';
import {AppRoute} from '../routes/Routes';

function App({
  purchId,
  purchPin,
  JADConfirmedBy,
  items,
  errors,
  token,
  history,
  modal,
  tokenCheck,
  clearDetailsDispatch,
  toggleTimeOutModal,
  clearModal,
  initialLoadProp,
  setInitialLoadProp,
}) {
  const [error, setError] = useState();
  const [isIE, setIsIE] = useState(false);

  useEffect(() => {
    try {
      if (isBrowserIE()) {
        setIsIE(true);
        history.replace('/browser-not-supported');
      }
    } catch (e) {
      console.log(e);
    }
  }, [history]);

  useEffect(() => {
    if (!initialLoadProp) {
      GoogleAnalyticsHelper.trackPage(window.location.href);
      setInitialLoadProp();
    }
  }, [initialLoadProp, setInitialLoadProp]);

  useEffect(() => {
    purchId && tokenCheck(purchId, token);
    clearModal();
  }, [clearModal, purchId, token, tokenCheck]);

  useEffect(() => {
    if (errors && errors.tokenExpired) {
      clearDetailsDispatch();
      history.push('/session_expired');
    }
    if (errors && errors.timeOut) {
      setError(errors.timeOut);
      toggleTimeOutModal();
    }
    clearErrors();
  }, [clearDetailsDispatch, errors, history, toggleTimeOutModal]);

  useEffect(() => {
    history.listen(location => {
      GoogleAnalyticsHelper.trackPage(location.pathname);
    });
  }, [history]);

  const navToHome = () => {
    purchId && tokenCheck(purchId, token);
    history.push('/');
  };

  const navBack = () => history.goBack();
  const navToLogin = () => history.push('/login');
  const navToHelp = () => history.push('/help');
  const navToFraudAwareness = () => history.push('/fraudaware');
  const navToFraudWarning = () => history.push('/fwdwarning');
  const navToSuppliersNotification = () =>
    history.push('/suppliernotification');

  const doLogout = useCallback(() => {
    if (!purchId && !purchPin) return;
    clearDetailsDispatch();
    history.push('/login');
  }, [clearDetailsDispatch, history, purchId, purchPin]);

  const modalCallback = () => clearModal();

  const renderAlertModal = (
    <AlertModal
      showModal={modal && modal.timeOutModal}
      customStyle={errorStyle}
      error={error}
      renderTitle={ERROR_TITLE}
      renderContent={error}
      callback={modalCallback}
    />
  );

  const renderAppRoute = (
    <AppRoute
      purchId={purchId}
      token={token}
      errors={errors}
      navToFraudAwareness={navToFraudAwareness}
      navToFraudWarning={navToFraudWarning}
      navToLogin={navToLogin}
      navToSuppliersNotification={navToSuppliersNotification}
      navBack={navBack}
      purchPin={purchPin}
      tokenCheck={tokenCheck}
      items={items}
      JADConfirmedBy={JADConfirmedBy}
    />
  );

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <GlobalStyled />
        <TopBar
          purchId={purchId}
          JADConfirmedBy={JADConfirmedBy}
          navToHome={navToHome}
          navToHelp={navToHelp}
          doLogout={doLogout}
          isIE={isIE}
        />
        <StyledApp>
          <Wrapper>
            <Header isIE={isIE} />
            {/*{renderAppRoute}*/}
            <Switch>
              <Route
                path="/"
                exact
                render={() =>
                  purchId && token ? (
                    <Redirect to={'/dashboard'} />
                  ) : (
                    <Redirect to={'/login'} />
                  )
                }
              />
              <Route
                path="/login/:idParam?/:pinParam?"
                render={() => (
                  <LoginForm
                    errors={errors}
                    navToFraudAwareness={navToFraudAwareness}
                    navToFraudWarning={navToFraudWarning}
                    navToSuppliersNotification={navToSuppliersNotification}
                  />
                )}
              />
              <Route
                path="/dashboard"
                render={() =>
                  purchId &&
                  token && (
                    <VendorDashboard
                      token={token}
                      purchId={purchId}
                      purchPin={purchPin}
                      tokenCheck={tokenCheck}
                    />
                  )
                }
              />
              <Route
                path="/orderpage"
                render={() =>
                  !!purchId && (
                    <ConfirmOrder token={token} purchasedItems={items} />
                  )
                }
              />
              <Route
                path="/deliverypage"
                render={() =>
                  !!purchId &&
                  JADConfirmedBy && <DeliveryScreen token={token} />
                }
              />
              <Route
                path="/session_expired"
                render={() => (
                  <SessionExpired purchId={purchId} navToLogin={navToLogin} />
                )}
              />
              <Route
                path="/help"
                render={() => <HelpPage navBack={navBack} />}
              />
              <Route path="/fraudaware" render={() => <FraudAwareness />} />
              <Route path="/fwdwarning" render={() => <FraudWarning />} />
              <Route
                path="/suppliernotification"
                render={() => <SupplierNotification />}
              />
              <Route
                path="/browser-not-supported"
                render={() => <BrowserNotSupported />}
              />
              <Route render={() => <NotFound />} />
            </Switch>
          </Wrapper>
        </StyledApp>
        <Footer />
        {renderAlertModal}
      </Container>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    purchId: state.auth.PurchId,
    purchPin: state.auth.PurchPin,
    JADConfirmedBy: state.purchaseOrder.JADConfirmedBy,
    token: state.auth.token,
    errors: state.errors,
    nextRoute: state.nextRoute,
    bookingAreas: state.booking.bookingAreas,
    modal: state.modal,
    items: state.purchaseOrder.items,
  };
};

const mapDispatchToProps = dispatch => ({
  resetNextRoute: bindActionCreators(resetNextRoute, dispatch),
  clearDetailsDispatch: bindActionCreators(clearDetailsDispatch, dispatch),
  tokenCheck: bindActionCreators(tokenCheck, dispatch),
  clearErrors: bindActionCreators(clearErrors, dispatch),
  clearModal: bindActionCreators(clearModal, dispatch),
  toggleTimeOutModal: bindActionCreators(toggleTimeOutModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
