import {createStore, applyMiddleware, compose} from 'redux';
import throttle from 'lodash/throttle';
import createSagaMiddleware from 'redux-saga';
import {captureException, setTag} from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';

import reducers from '../reducers/index.reducer';
import {loadState, saveState} from './localStorage';
import saga from '../saga/saga';

const onError = error => {
  setTag('environment', 'dev');
  captureException(error);
};

const sentryMiddleWare = createSentryMiddleware(Sentry, {
  breadcrumbDataFromAction: action => action,
});
const sagaMiddleware = createSagaMiddleware({onError});

// const checkTokenExpirationMiddleware = store => next => action => {
//   console.log('dispatch action to check token');
//   next(action);
// };

const configureStore = () => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const persistedState = loadState();

  const store = createStore(
    reducers,
    persistedState,
    composeEnhancers(applyMiddleware(sagaMiddleware, sentryMiddleWare))
  );

  store.runSaga = sagaMiddleware.run(saga);
  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }),
    1000
  );
  return store;
};

export default configureStore;
