export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i;
export const CONTACT_NUMBER = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;
export const IS_NUMBER = /^[0-9]+$/;

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_STRING_FORMAT = 'dddd Do of MMMM YYYY';

export const ERROR_TITLE = 'ERROR';

export const ERROR_INFO =
  'Please try again, or give us a call on 23042343209423 if this problem persists';
export const SLOTS_ERROR =
  'There was an error finding available time slots for your delivery';

export const CONFIRMATION_SUCCESS_TITLE = 'Confirmation Successful';
export const CONFIRMATION_SUCCESS_MESSAGE =
  'Your order is now confirmed. Please go to the booking page to arrange the delivery';

export const BOOKING_SUCCESS_TITLE = 'Booking Successful';
export const BOOKING_SUCCESS_MESSAGE =
  'Your delivery has now been updated. If you you need to make any further changes, please update the form and resubmit the delivery';

export const ADD_POS_DESCRIPTION =
  'If you have added or removed a purchase order, please confirm the new quantity and reselect the time slot before updating your delivery.\n';

export const PLEASE_SELECT_A_TIME = 'Please select a time';
export const DELIVERY_AREAS = {
  QTY: 'QtyKey',
  UNIT: 'UnitKey',
  WMS_AREA: 'WMSStoreArea',
};

export const DELIVERY_UNITS = {
  QTY: 'Qty',
  STORE_AREA_ID: 'StoreAreaId',
  UNIT_ID: 'UnitId',
};

export const PURCHASING_CONTACT_NUMBER = '00 44 1992 701710';
export const PURCHASING_EMAIL_ADDRESS = 'Purchase@jjfoodservice.com';

export const HTTP_ERROR_CODES = {
  TOKEN_EXPIRED: '420',
};

export const ERRORS = {
  EXCEPTION: 'Exception',
  TOKEN_EXPIRED: 'Token Expired',
  AXIOS_TIMEOUT_ERROR:
    'Could not connect to the server. Please try again later.',
  LOGIN_ERROR: 'Login failed',
  GET_PURCHASE_ORDER_ERROR:
    'There was a problem while trying to fetch your purchase order. Please contact purchasing or try again later',
  CONFIRM_PURCHASE_ORDER_ERROR:
    'There was a problem while trying to confirm your order. Please contact purchasing or try again later',
  BOOKING_DETAILS_ERROR:
    'There was a problem while trying to fetch the details for this booking. Please contact purchasing or try again later',
  BOOKING_AREAS_ERROR:
    'Failed to find the booking areas. Please contact purchasing or try again later',
  BOOKING_UNITS_ERROR:
    'Failed to find the booking units. Please contact purchasing or try again later',
  ADD_PURCHASE_ORDER_ERROR: 'Invalid PO or PIN',
  REMOVE_PURCHASE_ORDER_ERROR: 'Unable to remove PO',
  TIME_SLOTS_ERROR:
    'Sorry, no available time slots could be found. Please try again later or contact purchasing',
  UPDATE_BOOKING_ERROR:
    'There was a problem while updating the booking. Please try again later or contact purchasing',
  UPDATE_DELIVERY_ERROR:
    'There was a problem while updating the delivery. Please try again later or contact purchasing',
  ADD_DELIVERY_ERROR:
    'There was a problem adding the delivery. Please contact purchasing or try again later',
};

export const SIZES = {
  MEDIUM: 'medium',
  SMALL: 'small',
  SMALLER: 'smaller',
};
