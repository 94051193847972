import React from 'react';

import {
  Header,
  Container,
  SubHeader,
  MainText,
  BackButton,
  Title,
  Grid,
  Row,
  Col,
  AddressHeader,
  AddressContainer,
  Link,
} from './Fraud.style';
import {SubHeaderCenter} from '../../styles/generic.style';

function FraudWarning(props) {
  return (
    <Container>
      <Title>WARNING NOTICE BY FWD</Title>
      <SubHeaderCenter>NOVEMBER 2012</SubHeaderCenter>
      <img
        src={'https://secure.jjfoodservice.com/vss/headpiece/images/pic03.jpg'}
      />
      <Grid>
        <Row>
          <Col size={7}>
            <Header>
              Warning Notice by FWD: Advisory note for Suppliers and
              Manufacturers
            </Header>
            <SubHeader>Business identity fraud warning:</SubHeader>
            <SubHeader>Impersonation of UK wholesalers:</SubHeader>
            <MainText>
              A number of incidents of business identity involving UK
              wholesalers have been reported to the Federation of Wholesale
              Distributors. In each case goods have been ordered from a European
              supplier by criminals using the name of a FWD member. On arrival
              in the UK the goods are diverted from the stated delivery address.
              The wholesaler has no knowledge at this stage that their name is
              being falsely used to obtain goods. FWD member companies cannot be
              held responsible for the loss of goods they neither ordered not
              received.
            </MainText>
            <MainText>
              How does the fraud work? Food product and ingredient suppliers in
              EU countries have been contacted by fraudsters claiming to be from
              a UK wholesaler interested in ordering a large volume of stock. In
              most cases there is no previous relationship between the supplier
              and the wholesaler and no existing account. On occasion the name
              of an existing account holder will be used, claiming that the
              order is urgent and there is not time to follow usual ordering
              procedures.
            </MainText>
            <MainText>
              The contact usually comes via a mobile phone or email. If by
              email, the address may be similar to the wholesalers real domain.
              The contact name, delivery address and billing address given may
              be genuine. The supplier may be advised to run a credit check on
              the company, or take out credit insurance. In some cases, a false
              website has been created with a similar domain name. This site
              will contain false contact details.
            </MainText>
            <MainText>
              The goods are shipped by the supplier or its agent in the UK. In
              some cases the delivery address given will have no connection to
              the genuine wholesaler. In others, the vehicle driver will be
              informed that the delivery address has been changed, and the stock
              is diverted into the hands of the criminals. A fake delivery note
              will be issued.
            </MainText>
            <MainText>
              The wholesaler only becomes aware of the crime when it receives a
              demand for payment from the supplier. Without the correct proof of
              order or delivery, the wholesaler cannot be held liable for the
              loss of the goods.
            </MainText>
            <MainText bold={true}>What should you do?</MainText>
            <MainText>
              Suppliers should carry our checks to ensure their customers are
              genuine:
              <ul>
                <li>
                  Take a contact name, land line phone number and web site
                  address.
                </li>
                <li>
                  Confirm wholesalers contact details are the same as are
                  published in the Wholesale News Retail Review (available at
                  www.wholesalenews.co.uk
                </li>
                <li>
                  Check email address against customer company’s genuine
                  address.
                </li>
                <li>Check company website for genuine delivery address.</li>
                <li>
                  If in doubt, call the contact phone number for the company to
                  check the name on the purchase order exists at the customer
                  company. If so, check they have made the order.
                </li>
                <li>
                  Treat last minute changes of delivery address with suspicion.
                  Double check changes with the wholesaler.
                </li>
                <li>
                  Beware or urgent or casual orders from existing customers.
                  Insist they follow usual purchasing procedures
                </li>
                <li>
                  Educate vehicle crews to deliver only to the specified
                  destination, and report any attempt to change the delivery
                  destination before off-loading the goods.
                </li>
              </ul>
            </MainText>
            <MainText bold={true}>Wholesalers should:</MainText>
            <ul>
              <li>
                Always make orders through established channels, including
                issuing a purchase order.
              </li>
              <li>
                Not change the delivery destination once the goods are in
                transit.
              </li>
              <li>
                Report any unexpected deliveries at their premises to the
                supplier, including details of vehicle and delivery documents.
              </li>
              <li>
                Report any unexpected deliveries or invoices to Action Fraud
                0300 123 2040
              </li>
            </ul>
            <Link href={'https://www.actionfraud.police.uk'}>
              www.actionfraud.police.uk
            </Link>
          </Col>
          <Col size={3}>
            <Header>FWD Address</Header>
            <AddressContainer>
              <div>Federation of Wholesale Distributors</div>
              <div>9 Gildredge Road</div>
              <div>Eastbourne</div>
              <div>East Sussex</div>
              <div>BN21 4RB</div>
            </AddressContainer>
          </Col>
        </Row>
      </Grid>
    </Container>
  );
}

export default FraudWarning;
