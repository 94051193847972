import React from 'react';

import {TopHeader} from './Login.style';
import {MainHeader, Header, Text} from '../../styles/generic.style';
import {PURCHASING_CONTACT_NUMBER} from '../../constants/constants';

const Body = () => (
  <div>
    <TopHeader>Welcome To The Vendor Self Service Portal (VSS)</TopHeader>
    <Text>
      This portal gives you secure access to our order confirmation system and
      delivery booking in system. If you have any questions call our team on{' '}
      {PURCHASING_CONTACT_NUMBER}. Please enter the information found on your
      purchase order confirmation.
      <Text margin={'2rem 0 0 0'}>
        This system is designed to be used by our JJ Food Service Vendors ONLY.
      </Text>
    </Text>
  </div>
);

export default Body;
