import React from 'react';

import ModalClass from './Modal';
import {ModalWrapper} from './Modal.style';

const PurchasedItemsModal = props => {
  const {renderContent, showModal, onClose, customStyle} = props;

  return (
    <ModalClass isOpen={showModal} onClose={onClose} customStyle={customStyle}>
      <ModalWrapper>{renderContent}</ModalWrapper>
    </ModalClass>
  );
};

export default PurchasedItemsModal;
