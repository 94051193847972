import {INITIAL_STATE} from './initial.state';
import {LOGIN_SUCCESS, LOGIN_FAILED} from '../actions/types.action';

export default (state = INITIAL_STATE.auth, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        PurchId: action.purchId,
        PurchPin: action.purchPin,
        token: action.token,
      });
    case LOGIN_FAILED:
      return Object.assign({}, state, {
        PurchId: null,
        PurchPin: null,
        token: null,
      });
    default:
      return state;
  }
};

export const getTokenFromState = state => state.auth.token;
