import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../../assets/logo.png';
import {Wrapper, Logo, Right} from './Header.style';

const Header = props => (
  <Wrapper>
    <Link to={!props.isIE ? '/' : undefined}>
      <Logo src={logo} alt="JJ FoodService" />
    </Link>
  </Wrapper>
);

export default Header;
