import styled from 'styled-components';
//import {media} from '../../Styles/media-queries';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
  height: 11rem;
  position: relative;
  z-index: 10;
`;

export const Logo = styled.img`
  width: 25rem;
  max-height: 5rem;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Right = styled(Left)`
  justify-content: flex-end;
`;

export const Button = styled.button`
  fontSize: 2.5rem;
  border: "solid 2px";
  width: "20rem;
;`;
