export const LOGIN_REQUESTED = 'LOGIN_ACTION';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_REQUESTED = 'LOGOUT_ACTION';

export const TOKEN_CHECK_REQUESTED = 'TOKEN_CHECK_REQUESTED';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';

export const PURCHASE_ORDER_REQUESTED = 'PURCHASE_ORDER_REQUESTED';
export const PURCHASE_ORDER_SUCCESS = 'PURCHASE_ORDER_SUCCESS';
export const PURCHASE_ORDER_FAILED = 'PURCHASE_ORDER_FAILED';
export const STORE_PURCHASE_ORDER = 'STORE_PURCHASE_ORDER';

export const CONFIRM_ORDER_REQUESTED = 'CONFIRM_ORDER_REQUESTED';
export const CONFIRM_ORDER_SUCCESS = 'CONFIRM_ORDER_SUCCESS';
export const CONFIRM_ORDER_FAILED = 'CONFIRM_ORDER_FAILED';

export const CLEAR_DETAILS = 'CLEAR_DETAILS';
export const REFRESH_PO = 'REFRESH_PO';

//BOOKING
export const BOOKING_DETAILS_REQUESTED = 'GET_BOOKING_DETAILS';
export const REFRESH_BOOKING_DETAILS = 'REFRESH_BOOKING_DETAILS_REQUESTED;';
export const BOOKING_DETAILS_SUCCESS = 'BOOKING_DETAILS_SUCCESS';
export const BOOKING_DETAILS_FAILED = 'BOOKING_DETAILS_FAILED';

export const BOOKING_ID_REQUESTED = 'BOOKING_ID_REQUESTED';
export const BOOKING_ID_SUCCESS = 'BOOKING_ID_SUCCESS';
export const BOOKING_ID_FAILED = 'BOOKING_ID_FAILED';

export const BOOKING_AREAS_REQUESTED = 'UPDATE_BOOKING_AREAS';
export const BOOKING_AREAS_SUCCESS = 'BOOKING_AREAS_SUCCESS';
export const BOOKING_AREAS_FAILED = 'BOOKING_AREAS_FAILED';

export const BOOKING_UNITS_REQUESTED = 'UPDATE_BOOKING_UNITS';
export const BOOKING_UNITS_SUCCESS = 'BOOKING_UNITS_SUCCESS';
export const BOOKING_UNITS_FAILED = 'BOOKING_UNITS_FAILED';

export const TIME_SLOTS_REQUESTED = 'UPDATE_BOOKING_SLOTS';
export const TIME_SLOTS_SUCCESS = 'BOOKING_SLOTS_SUCCESS';
export const TIME_SLOTS_FAILED = 'BOOKING_SLOTS_FAILED';
export const CLEAR_TIME_SLOTS = 'CLEAR_TIME_SLOTS';

export const ADD_PO_REQUESTED = 'ADD_PO_REQUESTED';
export const ADD_PO_SUCCESS = 'ADD_PO_SUCCESS';
export const ADD_PO_FAILED = 'ADD_PO_FAILED';

export const REMOVE_PO_REQUESTED = 'REMOVE_PO_REQUESTED';
export const REMOVE_PO_SUCCESS = 'REMOVE_PO_SUCCESS';
export const REMOVE_PO_FAILED = 'REMOVE_PO_FAILED';

export const ADD_DELIVERY_REQUESTED = 'ADD_DELIVERY_REQUESTED';
export const ADD_DELIVERY_SUCCESS = 'ADD_DELIVERY_SUCCESS';
export const ADD_DELIVERY_FAILED = 'ADD_DELIVERY_FAILED';

export const UPDATE_BOOKING_REQUESTED = 'UPDATE_BOOKING_REQUESTED';
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_FAILED = 'UPDATE_BOOKING_FAILED';

export const UPDATE_DELIVERY_REQUESTED = 'UPDATE_DELIVERY_REQUESTED';
export const UPDATE_DELIVERY_SUCCESS = 'UPDATE_DELIVERY_SUCCESS';
export const UPDATE_DELIVERY_FAILED = 'UPDATE_DELIVERY_FAILED';

export const SUBMIT_DELIVERY_REQUESTED = 'SUBMIT_DELIVERY_REQUESTED';

export const NAVIGATE_TO_ROUTE = 'NAVIGATE_TO_ROUTE';
export const NAVIGATE_RESET = 'NAVIGATE_RESET';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const AXIOS_TIMEOUT = 'AXIOS_TIMEOUT';

export const TOGGLE_PO_CONFIRMED_MODAL = 'TOGGLE_ALERT_MODAL';
export const TOGGLE_PURCHASED_ITEMS = 'TOGGLE_PURCHASED_ITEMS';
export const TOGGLE_BOOKING_DETAILS_MODAL = 'TOGGLE_BOOKING_DETAILS_MODAL';
export const TOGGLE_DELIVERY_UPDATE_MODAL = 'TOGGLE_DELIVERY_UPDATE_MODAL';
export const TOGGLE_GETUNITS_FAILED_MODAL = 'TOGGLE_GETUNITS_FAILED_MODAL';
export const TOGGLE_GETAREAS_FAILED_MODAL = 'TOGGLE_GETAREAS_FAILED_MODAL';
export const TOGGLE_TIMEOUT_MODAL = 'TOGGLE_TIMEOUT_MODAL';
export const CLEAR_MODAL = 'CLEAR_MODAL';
