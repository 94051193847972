import {INITIAL_STATE} from './initial.state';
import {NAVIGATE_RESET, NAVIGATE_TO_ROUTE} from '../actions/types.action';

export default (state = INITIAL_STATE.nextRoute, action) => {
  switch (action.type) {
    case NAVIGATE_TO_ROUTE:
      return action.nextRoute;
    case NAVIGATE_RESET:
      return null;
    default:
      return state;
  }
};
