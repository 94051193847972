import styled from 'styled-components';
import {media} from '../../styles/media-queries';
import theme from '../../styles/theme';
import {Button3b, Button3c} from '../../styles/components/buttons';

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 50rem;
  height: 30rem;
  position: relative;
  z-index: 10;
`;

export const StyledWrapper = styled(ModalWrapper)`
  background-color: ${p =>
    p.isError ? p.theme.tertiaryAltColor : p.theme.primaryColor};
  width: 60rem;
`;

export const Content = styled.div`
  background-color: ${p => p.theme.tertiaryBgColor};
  color: ${p => p.theme.textColor};
  align-items: center;
  display: flex;
  height: 100%;
`;

export const TextWrapper = styled.div`
  display: inline-block;
  text-align: center;
  align-self: center;
  line-height: 2.4rem;
  font-size: 1.6rem;
  margin: 0 3rem;
  width: 100%;
  ${media.smaller`
    line-height: 2rem;
    padding: 0;
  `};
`;

export const Title = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding: 3rem;
  width: 100%;
  color: ${props =>
    props.error ? theme.tertiaryAltColor : theme.primaryColor};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ErrorButton = styled(Button3c)`
  width: 12rem;
  text-align: center;
  position: relative;
  margin: 5rem;
  &:hover {
    svg {
      fill: ${p => p.theme.secondaryBgColor};
    }
  }
${media.smaller`
  width: ${p => (p.fullWidth ? '100%' : '15rem')};
`};
${media.smallest`
  text-align: center;
  height: auto;
`};
}
`;

export const SuccessButton = styled(Button3b)`
  width: 12rem;
  text-align: center;
  position: relative;
  margin: 5rem;
  &:hover {
    svg {
      fill: ${p => p.theme.secondaryBgColor};
    }
  }
${media.smaller`
  width: ${p => (p.fullWidth ? '100%' : '15rem')};
`};
${media.smallest`
  text-align: center;
  height: auto;
`};
}
`;
