import {createStore, applyMiddleware} from 'redux';
import throttle from 'lodash/throttle';
import createSagaMiddleware from 'redux-saga';
import {captureException, setTag} from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';

import reducers from '../reducers/index.reducer';
import {loadState, saveState} from './localStorage';
import saga from '../saga/saga';

const onError = error => {
  setTag('environment', 'prod');
  captureException(error);
};
const sentryMiddleWare = createSentryMiddleware(Sentry, {});
const sagaMiddleware = createSagaMiddleware({onError});

const configureStore = () => {
  console.log('initialise prod store');
  const persistedState = loadState();
  const store = createStore(
    reducers,
    persistedState,
    applyMiddleware(sagaMiddleware, sentryMiddleWare)
  );

  store.runSaga = sagaMiddleware.run(saga);
  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }),
    1000
  );
  return store;
};

export default configureStore;
