import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {clearDetailsDispatch} from '../../actions/auth.action';
import {
  Header,
  Container,
  SubHeader,
  SubmitButton,
  MainText,
} from './SessionExpired.style';

function SessionExpired(props) {
  const {navToLogin, clearDetailsDispatch, purchId} = props;
  useEffect(() => {
    purchId && clearDetailsDispatch();
  }, [clearDetailsDispatch]);

  return (
    <Container>
      <Header>Your Session has Expired</Header>
      <MainText>
        You have been logged out. This may have been due to a period of
        inactivity.
      </MainText>
      <SubHeader>Click below to return to the login page</SubHeader>
      <SubmitButton onClick={navToLogin}>GO TO LOGIN</SubmitButton>
    </Container>
  );
}

const mapDispatchToProps = dispatch => ({
  clearDetailsDispatch: bindActionCreators(clearDetailsDispatch, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(SessionExpired));
