import * as ActionTypes from './types.action';

export const logInWithPO = (purchId, purchPin) => ({
  type: ActionTypes.LOGIN_REQUESTED,
  purchId,
  purchPin,
});

export const tokenCheck = (purchId, token) => ({
  type: ActionTypes.TOKEN_CHECK_REQUESTED,
  purchId,
  token,
});

export const clearDetailsDispatch = () => ({
  type: ActionTypes.LOGOUT_REQUESTED,
});
