import styled from 'styled-components';
import {media} from '../../styles/media-queries';
import theme from '../../styles/theme';
import {SubmitButton3a} from '../../styles/components/buttons';
import {
  MainHeader,
  SubHeaderSm,
  DescriptiveText,
} from '../../styles/generic.style';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 2rem 3rem;
  position: relative;
  z-index: 10;
  border: solid 3px ${theme.borderDarkColor};
  margin-top: 5rem;
  ${media.small`
    height: 45rem;
  `};
  ${media.smaller`
    height: 50rem;
  `};
`;

export const Header = styled(MainHeader)`
  text-align: Left;
  padding: 2rem 0;
  font-size: 2.2rem;
`;

export const SubHeader = styled(SubHeaderSm)`
  width: 100%;
`;

export const MainText = styled(DescriptiveText)`
  padding: 0;
`;

export const SubmitButton = styled(SubmitButton3a)`
  width: 19rem;
  text-align: center;
  margin: 2rem 0;
  &:hover {
    svg {
      fill: ${p => p.theme.secondaryBgColor};
    }
  }
${media.smaller`
  width: ${p => (p.fullWidth ? '100%' : '15rem')};
`};
${media.smallest`
  text-align: center;
  height: auto;
`};
}
`;
