import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Formik, Form, ErrorMessage} from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import {ModalWrapper} from '../../components/Modal/Modal.style';
import {errorStyle, successStyle} from '../../components/Modal/Modal.config';
import {purchasedItemsStyle} from '../../components/Modal/Modal.config';
import {StickyHeadTable} from '../../components/Table/Table';
import {confirmOrder} from '../../actions/confirmOrder.action';
import {clearModal, togglePurchasedItems} from '../../actions/modal.action';
import {clearDetailsDispatch} from '../../actions/auth.action';
import {clearErrors} from '../../actions/errors.action';
import {TextInput} from '../../components/Form/Field.form';
import PurchasedItemsModal from '../../components/Modal/PurchasedItemsModal';
import {
  DATE_STRING_FORMAT,
  ERROR_TITLE,
  CONFIRMATION_SUCCESS_MESSAGE,
  CONFIRMATION_SUCCESS_TITLE,
} from '../../constants/constants';
import AlertModal from '../../components/Modal/AlertModal';
import theme from '../../styles/theme';
import {
  Wrapper,
  StyledForm,
  OrderConfirmedMessage,
  ConfirmButton,
  BackButton,
  Center,
  Left,
  ItemsButton,
} from './ConfirmOrder.style';
import {
  Row,
  SubHeader,
  SubHeaderSm,
  FlexContainer,
  ErrorText,
  Block,
} from '../../styles/generic.style';

function ConfirmOrder(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();

  const {
    purchId,
    purchPin,
    items,
    JADConfirmedBy,
    deliveryAddress,
    JADVendEmail,
    confirmOrder,
    token,
    deliveryDate,
    history,
    errors,
    clearErrors,
    isLoading,
    modal,
    clearModal,
    togglePurchasedItems,
  } = props;

  useEffect(() => {
    if (isLoading) return setIsSubmitting(true);
    else return setIsSubmitting(false);
  }, [isLoading]);

  useEffect(() => {
    if (errors && errors.confirmOrder) {
      setError(errors.confirmOrder);
      clearErrors();
    }
  }, [errors, clearErrors]);

  const onGoBack = () => {
    props.history.push('/dashboard');
  };

  const onFormSubmit = values => {
    confirmOrder(purchId, purchPin, deliveryDate, token, values);
  };

  const modalCallback = () => {
    clearModal();
    !error && history.push('/dashboard');
  };

  const renderHeader = (
    <Row>
      <SubHeader padding={'2rem'}>PURCHASE ORDER : {purchId}</SubHeader>
    </Row>
  );

  const headerMessage = JADConfirmedBy ? (
    <OrderConfirmedMessage>
      This order has already been confirmed
    </OrderConfirmedMessage>
  ) : (
    <OrderConfirmedMessage>
      Your order needs to be confirmed. Please complete the form below to
      confirm it
    </OrderConfirmedMessage>
  );

  const purchasedItemsErrorMessage = (
    <div>
      <h2>Sorry there was a problem getting your items.</h2>
      <div>Please try again later.</div>
    </div>
  );

  const renderModalContent = (
    <ModalWrapper>
      {items ? <StickyHeadTable rows={items} /> : purchasedItemsErrorMessage}
    </ModalWrapper>
  );

  const renderNameandEmail = (
    <FlexContainer>
      <Row>
        <Block size={1.5}>
          <SubHeaderSm>Contact Name</SubHeaderSm>
        </Block>
        <Block size={4}>
          <TextInput name="name" disabled={JADConfirmedBy} />
        </Block>
        <Block size={2}>
          <ErrorText>
            <ErrorMessage name="name" />
          </ErrorText>
        </Block>
      </Row>
      <Row>
        <Block size={1.5}>
          <SubHeaderSm>Email Address</SubHeaderSm>
        </Block>
        <Block size={4}>
          <TextInput name="email" disabled={JADConfirmedBy} />
        </Block>
        <Block size={2}>
          <ErrorText>
            <ErrorMessage name="email" />
          </ErrorText>
        </Block>
      </Row>
    </FlexContainer>
  );

  const renderDeliveryAddress = (
    <FlexContainer>
      <Row>
        <Block size={1.5}>
          <SubHeaderSm>Delivery Address</SubHeaderSm>
        </Block>
        <Block size={4}>
          <TextInput name="deliveryAddress" value={deliveryAddress} disabled />
        </Block>
        <Block size={2} />
      </Row>
    </FlexContainer>
  );

  const renderDeliveryDate = (
    <Row>
      <Block size={1.5}>
        <SubHeaderSm>Delivery date</SubHeaderSm>
      </Block>
      <Block size={4}>
        <TextInput
          name="deliveryDate"
          value={moment(deliveryDate).format(DATE_STRING_FORMAT)}
          disabled
        />
      </Block>
      <Block size={2} />
    </Row>
  );

  const renderItemsButton = (
    <Row>
      <Block size={1.5}>
        <SubHeaderSm>Purchased Items</SubHeaderSm>
      </Block>
      <Block size={4}>
        <ItemsButton onClick={() => togglePurchasedItems()} type="button">
          SHOW
        </ItemsButton>
      </Block>
      <Block size={2} />
    </Row>
  );

  const renderSubmitButton = (
    <Left>
      {JADConfirmedBy ? (
        <BackButton
          type="button"
          onClick={onGoBack}
          disabled={isSubmitting}
          color={theme.dimmerRed}
        >
          Go Back
        </BackButton>
      ) : (
        <ConfirmButton type="submit" disabled={isSubmitting}>
          {JADConfirmedBy ? 'Update Order' : 'Confirm Order'}
        </ConfirmButton>
      )}
    </Left>
  );

  const renderPurchasedItemsModal = (
    <PurchasedItemsModal
      showModal={modal && modal.purchasedItems}
      onClose={clearModal}
      customStyle={purchasedItemsStyle}
      renderContent={renderModalContent}
    />
  );

  const renderAlertModal = error && (
    <AlertModal
      showModal={modal && modal.alertModal}
      customStyle={error ? errorStyle : successStyle}
      error={error}
      renderTitle={error ? ERROR_TITLE : CONFIRMATION_SUCCESS_TITLE}
      renderContent={error || CONFIRMATION_SUCCESS_MESSAGE}
      callback={modalCallback}
    />
  );
  const renderOrderForm = (
    <Formik
      isSubmitting={false}
      enableReinitialize
      initialValues={{
        name: JADConfirmedBy || '',
        email: JADVendEmail || '',
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email()
          .required('Required'),
        name: Yup.string().required('Required'),
      })}
      onSubmit={values => {
        setIsSubmitting(true);
        onFormSubmit(values);
      }}
    >
      {() => (
        <StyledForm>
          {renderHeader}
          {headerMessage}
          <Form>
            {renderNameandEmail}
            {renderDeliveryAddress}
            {renderDeliveryDate}
            {renderItemsButton}
            {renderSubmitButton}
          </Form>
        </StyledForm>
      )}
    </Formik>
  );

  return (
    <Wrapper>
      {renderOrderForm}
      {renderPurchasedItemsModal}
      {renderAlertModal}
    </Wrapper>
  );
}

ConfirmOrder.propTypes = {
  purchId: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

const mapStateToProps = ({
  purchaseOrder,
  auth,
  nextRoute,
  errors,
  loading,
  modal,
}) => ({
  purchId: auth.PurchId,
  purchPin: auth.PurchPin,
  JADVendIP: purchaseOrder.JADVendIP,
  deliveryAddress: purchaseOrder.InventLocationAddress,
  JADConfirmedBy: purchaseOrder.JADConfirmedBy,
  JADVendEmail: purchaseOrder.JADVendEmail,
  deliveryDate: purchaseOrder.DeliveryDate,
  items: purchaseOrder.Items,
  token: auth.token,
  nextRoute,
  errors: errors,
  isLoading: loading.confirmOrder,
  modal,
});

export default connect(mapStateToProps, {
  confirmOrder,
  clearDetailsDispatch,
  clearErrors,
  clearModal,
  togglePurchasedItems,
})(withRouter(ConfirmOrder));
