import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import configureStore from './store/configureStore';
import Root from './client/Root';
import config from './config/config.json';
import {GoogleAnalyticsHelper} from './helpers/googleAnalytics.helper';

export const store = configureStore();
Sentry.init({dsn: config.sentryUrl});
GoogleAnalyticsHelper.initialise();
ReactDOM.render(<Root store={store} />, document.getElementById('root'));
