import styled from 'styled-components';
import ReactModal from 'react-modal';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0rem;
  height: 40rem;
  position: relative;
  z-index: 10;
  margin-top: 0rem;
`;

export const StyledReactModal = styled(ReactModal).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  & .Overlay {
    backgroundColor: red,
    position: fixed,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: 0,
    overflowY: auto,
    overflow: hidden,
    WebkitOverflowScrolling: touch,
    display: flex,
    justifyContent: center,
    alignItems: center,
    width: 100%,
    height: 100%,
    maxHeight: 100%,
    zIndex: 998,
  }
  & .Modal {...}
}`;
