import ReactGA from 'react-ga';
import {getApiConfig} from '../config/configProvider';

export class GoogleAnalyticsHelper {
  static INITIALIZED = false;

  static initialise() {
    const config = getApiConfig();
    if (!config.googleAnalytics) return;
    ReactGA.initialize(config.googleAnalyticsTrackingId, {
      debug: false,
    });
    this.INITIALIZED = true;
    console.log('initalised: ' + config.googleAnalyticsTrackingId);
  }

  static trackPage(location) {
    if (this.INITIALIZED) {
      ReactGA.set({page: location});
      ReactGA.pageview(location);
    }
  }

  static trackEvent(action, data) {
    if (this.INITIALIZED) {
      ReactGA.event({
        category: (data && data.category) || 'user',
        action,
      });
    }
  }
}
