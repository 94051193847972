import styled from 'styled-components';
import {spin} from '../../styles/components/keyframes';

export const Spinner = styled.div`
  width: 100%;
  text-align: center;
  svg {
    fill: ${p => (p.isLight ? p.theme.primaryTextColor : p.theme.primaryColor)};
  }
`;

export const LoadingPng = styled.img`
  width: 4rem;
  height: 4rem;
  animation: ${spin} 0.6s linear infinite;
`;
