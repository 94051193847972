import {AddressContainer, AddressHeader} from '../components/Fraud/Fraud.style';
import React from 'react';

export default class FraudHelper {
  static branchDetails = [
    {
      name: 'Enfield',
      lineOne: '7 Solar Way',
      lineTwo: 'Innova Park',
      town: 'Enfield',
      borough: 'Middlesex',
      postcode: 'EN3 7XY',
    },
    {
      name: 'JJ Basingstoke',
      lineOne: 'Kingsclere Road',
      lineTwo: 'Oakridge',
      town: 'Basingstoke',
      borough: 'Hampshire',
      postcode: 'RG21 5UG',
    },
    {
      name: 'JJ Birmingham',
      lineOne: 'Unit4, Ringway Business Park',
      lineTwo: '101 Avenue Road',
      town: 'Aston',
      city: 'Birmingham',
      borough: 'West Midlands',
      postcode: 'B7 4NA',
    },
    {
      name: 'JJ Bristol',
      lineOne: 'UNIT 5, The Cobden Centre',
      lineTwo: 'Folly Brook Road',
      town: 'Emersons Green',
      city: 'Bristol',
      postcode: 'BS16 7FQ',
    },
    {
      name: 'JJ Dagenham',
      lineOne: 'Unit 4, Orion Park',
      lineTwo: 'Messina Way',
      town: 'Dagenham',
      city: 'London',
      postcode: 'RM9 6FF',
    },
    {
      name: 'JJ Doncaster',
      lineOne: '4 Derbyshire Court',
      city: 'Doncaster',
      postcode: 'DN3 3FD',
    },
    {
      name: 'JJ Leeds',
      lineOne: 'Parkside Industrial Estate',
      lineTwo: 'Middleton Grove',
      town: ' Leeds',
      postcode: 'LS11 5BX',
    },
    {
      name: 'JJ Leicester',
      lineOne: '10 Murrayfield Road',
      lineTwo: 'Braunston Frith Industrial Estate',
      city: ' Leicester',
      postcode: 'LE3 1UW',
    },
    {
      name: 'JJ Manchester',
      lineOne: 'la Lamplight Way',
      lineTwo: 'Pendlebury',
      town: 'Swinton',
      city: 'Agecroft',
      borough: 'Greater Manchester',
      postcode: 'M27 8UJ',
    },
    {
      name: 'JJ Newcastle',
      lineOne: 'Sanderson Street',
      city: 'Newcastle',
      postcode: 'NE4 7LA',
    },
    {
      name: 'JJ Sidcup',
      lineOne: 'Unit 2',
      lineTwo: 'Five Arches Business Estate',
      town: 'Maidstone Road',
      city: 'Foots Cray',
      borough: 'Sidcup',
      postcode: 'DA14 5AG',
    },
  ];

  static divider = (<hr style={{height: 1}} />);

  static renderBranches = this.branchDetails.map(branch => (
    <AddressContainer>
      <AddressHeader>{branch.name}</AddressHeader>
      <div>{branch.lineOne}</div>
      <div>{branch.lineTwo}</div>
      <div>{branch.town}</div>
      <div>{branch.city}</div>
      <div>{branch.borough}</div>
      <div>{branch.postcode}</div>
      {this.divider}
    </AddressContainer>
  ));
}
