import styled from 'styled-components';
import {media} from '../../styles/media-queries';
import theme from '../../styles/theme';
import {Button3b} from '../../styles/components/buttons';
import {
  DescriptiveText,
  MainHeader,
  SubHeaderSm,
  Text,
} from '../../styles/generic.style';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem 3rem 5rem 3rem;
  position: relative;
  z-index: 10;
  border: solid 3px ${theme.borderDarkColor};
  margin-top: 3rem;
  ${media.small`
    height: 75rem;
  `};
  ${media.smaller`
    height: 85rem;
  `};
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100rem;
  margin: 0 auto;
  flex-direction: column;
  padding-top: 5rem;
  ${media.medium`
    width: 100%;
    padding: 2rem;
    margin: 0;
  `};
  ${media.smaller`
    width: 100%;
     padding: 2rem 0;
     padding-bottom: 0;
  `};
`;

export const Grid = styled.div``;

export const Row = styled.div`
  display: flex;
`;

export const Col = styled.div`
  flex: ${p => p.size}
  margin: 1rem;
  padding: ${p => p.padding}
  
`;

export const Header = styled(MainHeader)`
  text-align: Left;
  padding: 2rem 0;
  font-size: 2.2rem;
`;

export const Title = styled(MainHeader)`
  padding: 2rem 0;
  font-size: 3rem;
`;

export const SubHeader = styled(SubHeaderSm)`
  width: 100%;
`;

export const MainText = styled(Text)`
  margin-top: 2rem;
  font-weight: ${p => p.bold && 'bold'};
`;

export const AddressContainer = styled.div`
  margin-top 2rem;
`;
export const AddressHeader = styled(DescriptiveText)`
  padding: 0 1rem 1rem 0rem;
`;

export const PrimaryText = styled(DescriptiveText)`
  padding: 0;
`;

export const SecondaryText = styled(Text)`
  margin-top: 2rem;
  padding-top: 2rem;
  font-weight: ${p => p.bold && 'bold'};
`;

export const Link = styled.a`
  cursor: pointer;
  font-size: 1.5rem;
  margin: 0.3rem;
`;
