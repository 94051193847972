import styled from 'styled-components';
import {media} from '../../styles/media-queries';

export const Title = styled.div`
  font-size: 6rem;
  padding: 2rem;
  text-align: center;
  color: ${p => p.theme.tertiaryColor};
`;

export const Text = styled.div`
  font-size: 3rem;
  text-align: center;
  color: ${p => p.theme.textColor};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 4rem 0 2rem;
  ${media.smaller`
    margin: 0;
  `};
`;
