import styled from 'styled-components';
import {media} from '../../styles/media-queries';
import theme from '../../styles/theme';
import {SubmitButton3b} from '../../styles/components/buttons';
import {FormInput} from '../../styles/components/input';
import {ErrorText, MainHeader} from '../../styles/generic.style';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  height: 40rem;
  position: relative;
  z-index: 10;
  border: solid 3px ${theme.borderDarkColor};
  margin-top: 3rem;
  ${media.small`
    height: 45rem;
  `};
  ${media.smaller`
    height: 50rem;
  `};
`;

export const TopHeader = styled(MainHeader)`
  text-align: Left;
`;

export const Header = styled.h1`
  position: relative;
`;

export const SubHeader = styled.h2`
  position: relative;
  font-weight: normal;
`;

export const HeaderContainer = styled.div`
  justify-content: center;
  text-align: center;
`;

export const FormWrapper = styled.div`
  margin-top: 0rem;
  width: 100%;
`;

export const FormContainer = styled.div`
  width: 30%;
  justify-content: center;
  margin: 0 auto;
  ${media.smaller`
    width: 75%;
  `};
`;

export const ErrorContainer = styled.div`
  display: flex;
  padding: 2rem;
  font-weight: bold;
  color: red;
  margin: 0px auto;
  margin-top: 1rem;
  width: 25%;
  border: solid 2px red;
  ${media.medium`
    width: 35%;
  `};
  ${media.smaller`
    width: 70%;
  `};
`;

export const Block = styled.div`
  width: 100%;
  position: relative;
  margin-top: 2rem;
  ${media.small`
    width: 100%;
    margin-bottom: 2rem;
  `};
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Error = styled.div`
  color: red;
  text-align: center;
  font-weight: bold;
  margin-top: 2px;
`;

export const FieldErrorText = styled(ErrorText)`
  margin-top: 0rem;
  font-size: 1.3rem;
`;

export const LoadingWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
`;

export const ButtonContainer = styled.div`
  position: relative;
`;

export const Label = styled.div`
  position: absolute;
  font-weight: bold;
  background: white;
  bottom: 34px;
  color: ${p => (p.error ? p.theme.tertiaryColor : p.theme.primaryColor)};
  padding: 0 5px 0 5px;
  left: 10px;
`;

export const Field = styled(FormInput)`
  color: ${p => p.theme.textColor};
  margin: 0.4rem;
  border-color: ${p =>
    p.error ? p.theme.tertiaryColor : p.theme.primaryColor};
  border-style: solid;
  ${media.small`
    width: 100%;
  `};
`;

export const SubmitButton = styled(SubmitButton3b)`
  width: 19rem;
  text-align: center;
  margin-top: 15px;
  &:hover {
    svg {
      fill: ${p => p.theme.secondaryBgColor};
    }
  }
${media.smaller`
  width: ${p => (p.fullWidth ? '100%' : '15rem')};
`};
${media.smallest`
  text-align: center;
  height: auto;
`};
}
`;
