import {put, call, takeLatest} from 'redux-saga/effects';
import {getTokenApi, checkTokenCall} from '../api/auth.api';
import * as ActionTypes from '../actions/types.action';
import {purchaseOrder} from './purchaseOrder.saga';
import {ERRORS} from '../constants/constants';

function* getToken() {
  return yield call(getTokenApi);
}

function* login(action) {
  const {purchId, purchPin} = action;
  try {
    const token = yield* getToken();
    const poData = yield* purchaseOrder(purchId, purchPin, token);
    const nextRoute = poData.JADConfirmedBy ? '/dashboard' : '/orderpage';
    yield put({
      type: ActionTypes.LOGIN_SUCCESS,
      purchId,
      purchPin,
      token,
    });
    yield put({
      type: ActionTypes.PURCHASE_ORDER_SUCCESS,
      poData,
    });
    yield put({
      type: ActionTypes.NAVIGATE_TO_ROUTE,
      nextRoute: nextRoute,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.LOGIN_FAILED,
      // message: e.Description,
      message: ERRORS.LOGIN_ERROR,
    });
  }
}

function* logout() {
  yield put({
    type: ActionTypes.CLEAR_DETAILS,
  });
}

function* isTokenValid(action) {
  const {purchId, token} = action;
  try {
    yield call(checkTokenCall, purchId, token);
  } catch (e) {}
}

export default function* authYield() {
  yield takeLatest(ActionTypes.LOGIN_REQUESTED, login);
  yield takeLatest(ActionTypes.LOGOUT_REQUESTED, logout);
  yield takeLatest(ActionTypes.TOKEN_CHECK_REQUESTED, isTokenValid);
}
