import styled from 'styled-components';
import {media} from './media-queries';
import theme from './theme';
import {FormInput} from './components/input';

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: ${p => p.margin};
`;

export const ButtonContainer = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 100%;
  padding: 0.5rem;
  margin-top: ${p => p.margin};
  ${media.smaller`
    display:flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin: 0 auto;
  `};
  ${media.smallest`
  width: 100%;
`};
`;

export const Block = styled.div`
  flex: ${p => p.size || 1};
  margin: ${p => p.margin || '0.5rem'};
`;

export const InputBlock = styled(Block)`
  flex-shrink: 0;
  flex-basis: 100%
  flex-grow: 0;
`;

export const Label = styled.div`
  display: inline-block;
  position: absolute;
  pointer-events: none;
  transition: all 100ms ease-in;
  font-size: ${p => (p.isActive || p.hasText ? '1.1rem' : '1.5rem')};
  transform: translateY(${p => (p.isActive || p.hasText ? '-6px' : '15px')});
  padding: 0px 5px;
  margin-left: 10px;
  background: ${p => {
    if (p.isActive || p.hasText) return theme.primaryTextColor;
  }};
  color: ${p => {
    if (p.isActive && !p.error) return p.theme.primaryColor;
    else if ((p.error && p.isActive) || (p.error && p.hasText))
      return p.theme.tertiaryColor;
    else return p.theme.secondaryTextColor;
  }};
  ${media.medium`
  font-size: 1.4rem;
`};
  ${media.small`
  font-size: 1rem;
`};
`;

export const Field = styled(FormInput)`
  margin: 0.4rem;
  min-width: 8rem;
  border-color: ${p => {
    if (p.error) return p.theme.tertiaryColor;
    if (p.isActive && !p.error) return p.theme.primaryColor;
  }}
  border-style: solid;
`;

export const MainHeader = styled.h1`
  width: 100%;
  padding: ${p => p.padding};
  text-align: Center;
  color: ${theme.darkBlue};
`;

export const Header = styled.h2`
  text-align: Left;
  padding-bottom: ${p => (p.padding ? p.padding : '0')};
  width: 100%;
  color: ${theme.darkBlue};
  ${media.smaller`
    text-align: center;
  `}
`;

export const SubHeader = styled.h2`
  margin-top: ${p => p.margin || '0rem'};
  padding-top: ${p => p.padding || '0rem'}
  justify-content: flex-start;
  color: ${p => p.color || theme.darkBlue};
`;

export const SubHeaderCenter = styled.h2`
  padding: 6px;
  margin-top: 0rem;
  justify-content: center;
  text-align: center;
  color: ${theme.darkBlue};
`;

export const SubHeaderSm = styled.h2`
  font-size: 1.5rem;
  color: ${p => p.color || p.theme.darkBlue};
`;

export const SubHeaderCenterSm = styled(SubHeaderSm)`
  text-align: center;
`;

export const ErrorText = styled(SubHeaderSm)`
  font-style: italic;
  font-weight: none;
  margin-left: 1rem;
  color: ${p => (p.color ? p.color : theme.tertiaryColor)};
`;

export const DescriptiveText = styled.h3`
  padding: 0 1rem 3rem 0rem;
  font-size: 1.4rem;
  width: 100%;
  margin-top: 0rem;
  align-items: center;
  text-align: left;
`;

export const Text = styled.div`
  font-size: 1.4rem;
  margin: ${p => p.margin || null};
`;
