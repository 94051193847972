import styled from 'styled-components';
import {media} from '../../styles/media-queries';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.darktBgColor};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100rem;
  max-height: 100%;
  min-height: 3.5rem;
  margin: 0 auto;
  ${media.medium`
    width: 100%;
   `}
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${media.medium`
    min-height: 3.8rem;
  `};
`;

export const Right = styled(Left)`
  justify-content: flex-end;
  flex-direction: row;
`;

export const Item = styled.a`
  color: ${p => p.theme.primaryTextColor};
  cursor: pointer;
  font-size: 1.3rem;
  padding: 0 1.5rem;
  &:hover {
    color: ${p => p.theme.secondaryTextColor};
  }
`;

const truncate = () => {
  return `
    display: flex;
    font-size: 1.2rem;
    padding: 0.4rem 1.2rem;
    text-transform: uppercase;
    height: 3.8rem;
    font-weight: bold;
  `;
};

export const Description = styled.span`
  color: ${props => props.theme.secondaryTextColor};
  margin-right: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  ${media.medium`
    margin-left: 2rem;
  `};
  ${media.smaller`
    margin-left: 1rem;
  `};
`;

export const BlueButton = styled.div`
  ${truncate()};
  color: ${p => p.theme.primaryTextColor};
  background-color: ${p =>
    p.disable ? p.theme.disabledColor : p.theme.primaryColor};
  cursor: pointer;
  align-items: center;
  &:hover {
    color: ${p => p.theme.secondaryTextColor};
    background-color: ${p =>
      p.disable ? p.theme.tertiaryTextColor : p.theme.primaryColorHover};
  }
`;

export const GreenButton = styled.div`
  ${truncate()};
  color: ${p => p.theme.primaryTextColor};
  background-color: ${p =>
    p.disable ? p.theme.disabledColor : p.theme.secondaryColor};
  cursor: pointer;
  align-items: center;
  &:hover {
    color: ${p => p.theme.secondaryTextColor};
    background-color: ${p =>
      p.disable ? p.theme.tertiaryTextColor : p.theme.secondaryColorHover};
  }
`;
