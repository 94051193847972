const theme = {
  primaryColor: '#3594cc',
  primaryColorHover: '#3087ba',
  secondaryColor: '#26ae5d',
  secondaryColorHover: '#219550',
  tertiaryColor: '#e11f2f',
  tertiaryColorHover: '#bf1a28',
  tertiaryAltColor: '#f85351',
  textColor: '#797979',
  quaternaryColor: '#282828',
  primaryTextColor: '#fff',
  secondaryTextColor: '#e2e2e2',
  tertiaryTextColor: '#7c7c7c',
  primaryBgColor: '#87bede',
  darktBgColor: '#282828',
  secondaryBgColor: '#f3f3f3',
  tertiaryBgColor: '#fff',
  quaternaryBgColor: '#fffefa',
  secondaryBgColorHover: '#fbfbfb',
  borderColor: '#e1e1e1',
  borderDarkColor: '#d8d5d5',
  borderDarkerColor: '#c1c1c1',
  borderLightColor: '#25b760',
  selectionRangeColor: '#4ccc80',
  disabledColor: '#b2b2b2',
  errorBgColor: '#edd',
  darkBlue: '#234470',
  dimmerGreen: 'rgba(26, 212, 32, 0.6)',
  dimmerRed: 'rgba(212, 26, 26, 0.85)',
  dimmerBlack: 'rgba(57, 57, 57, 0.85)',
  iframeLinkColor: '#1e87f0',
  buttonShadow: 'rgba(34,36,38,.15)',
  lightBlueBg: '#e6f3ff',
  lightGreenBg: '#e6ffe6',
};

export default theme;
