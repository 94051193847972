import theme from '../../styles/theme';

export const overlay = {
  backgroundColor: 'rgba(120, 120, 120, 0.7)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  border: 0,
  overflowY: 'auto',
  overflow: 'hidden',
  WebkitOverflowScrolling: 'touch',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  zIndex: 998,
};

export const content = {
  top: 'initial',
  left: 'initial',
  right: 'initial',
  bottom: 'initial',
  padding: 0,
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  zIndex: 999,
  width: '100%',
  backgroundColor: theme.tertiaryBgColor,
  border: `1px solid ${theme.primaryColor}`,
};

export const purchasedItemsStyle = {
  overlay,
  content: {...content, overflow: 'scroll'},
};

export const successStyle = {
  overlay,
  content: {
    ...content,
    height: 'auto',
    width: 'auto',
    minWidth: '30rem',
    backgroundColor: theme.tertiaryBgColor,
    border: `2rem solid ${theme.primaryColor}`,
  },
};

export const errorStyle = {
  overlay,
  content: {
    ...content,
    height: 'auto',
    width: 'auto',
    minWidth: '30rem',
    border: `2rem solid ${theme.tertiaryAltColor}`,
  },
};

export const removePoStyle = {
  overlay,
  content: {
    ...content,
    height: 'auto',
    width: 'auto',
    border: `2rem solid ${theme.tertiaryAltColor}`,
  },
};
