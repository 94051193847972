import React from 'react';
import {useField} from 'formik';
import {Field} from '../../styles/generic.style';

export const TextInput = ({label, ...props}) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <Field className="text-input" {...field} {...props} />
    </>
  );
};
