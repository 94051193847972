import {combineReducers} from 'redux';
import auth from './auth.reducer';
import purchaseOrder from './purchaseOrder.reducer';
import booking from './booking.reducer';
import errors from './errors.reducer';
import loading from './loading.reducer';
import nextRoute from './nextRoute.reducer';
import modal from './modal.reducer';
import googleAnalytics from './googleAnalytics.reducer';
import {CLEAR_DETAILS} from '../actions/types.action';

const appReducer = combineReducers({
  auth,
  purchaseOrder,
  booking,
  loading,
  errors,
  nextRoute,
  modal,
  googleAnalytics,
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_DETAILS) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
