import {INITIAL_STATE} from './initial.state';
import {
  TOGGLE_PURCHASED_ITEMS,
  TOGGLE_PO_CONFIRMED_MODAL,
  TOGGLE_BOOKING_DETAILS_MODAL,
  CLEAR_MODAL,
  TOGGLE_DELIVERY_UPDATE_MODAL,
  TOGGLE_GETUNITS_FAILED_MODAL,
  TOGGLE_GETAREAS_FAILED_MODAL,
  TOGGLE_TIMEOUT_MODAL,
} from '../actions/types.action';

export default (state = INITIAL_STATE.modal, action) => {
  switch (action.type) {
    case TOGGLE_PURCHASED_ITEMS:
      return Object.assign({}, state, {purchasedItems: true});
    case TOGGLE_PO_CONFIRMED_MODAL:
      return Object.assign({}, state, {alertModal: true});
    case TOGGLE_BOOKING_DETAILS_MODAL:
      return Object.assign({}, state, {bookingDetailsModal: true});
    case TOGGLE_DELIVERY_UPDATE_MODAL:
      return Object.assign({}, state, {updateBookingModal: true});
    case TOGGLE_GETAREAS_FAILED_MODAL:
    case TOGGLE_GETUNITS_FAILED_MODAL:
      return Object.assign({}, state, {apiCallFailedModal: true});
    case TOGGLE_TIMEOUT_MODAL:
      return Object.assign({}, state, {timeOutModal: true});
    case CLEAR_MODAL:
      return null;
    default:
      return state;
  }
};
