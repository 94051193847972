import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const Input = styled.input`
  min-height: 4rem;
  min-width: 4rem;
  outline: none;
  padding: 0 1rem;
  font-size: 1.4rem;
`;

export const FormInput = styled(Input)`
  flex-basis: 90%;
  color: ${p => p.theme.textColor};
  margin: 0.4rem;
  border-color: ${p => (p.error ? p.theme.tertiaryColor : p.theme.borderColor)};
  border-style: solid;
  border-width: 0.1rem;
  width: ${p => (p.halfWidth ? '50%' : '100%')};
  ${media.smaller`
    width: 100%;
  `};
  &::placeholder {
    color: ${p => p.theme.quaternaryTextColor};
  }
`;

export const MaxChars = styled.span`
  color: ${p => p.theme.textColor};
  font-size: 1rem;
  position: absolute;
  right: 1rem;
  bottom: -1.6rem;
`;
