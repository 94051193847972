import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 4rem 0 2rem;
  ${media.smaller`
    margin: 0;
  `};
`;

export const Title = styled.div`
  font-size: 6rem;
  padding: 2rem;
  text-align: center;
  color: ${p => p.theme.tertiaryColor};
`;

export const Text = styled.div`
  font-size: 2rem;
  margin: 1rem;
  text-align: center;
  color: ${p => p.theme.textColor};
`;

export const TextLink = styled.a`
  color: ${p => p.theme.primaryColor};
`;

export const SubTitle = styled.h4`
  font-size: 3rem;
  font-weight: normal;
  text-align: center;
  color: ${p => p.theme.textColor};
`;
