import {INITIAL_STATE} from './initial.state';
import {
  LOGIN_FAILED,
  CLEAR_ERRORS,
  BOOKING_AREAS_FAILED,
  REMOVE_PO_FAILED,
  CONFIRM_ORDER_FAILED,
  ADD_PO_FAILED,
  CONFIRM_ORDER_REQUESTED,
  ADD_PO_REQUESTED,
  REMOVE_PO_REQUESTED,
  TIME_SLOTS_FAILED,
  TIME_SLOTS_REQUESTED,
  UPDATE_DELIVERY_FAILED,
  UPDATE_DELIVERY_REQUESTED,
  UPDATE_BOOKING_REQUESTED,
  UPDATE_BOOKING_FAILED,
  BOOKING_DETAILS_REQUESTED,
  BOOKING_DETAILS_FAILED,
  BOOKING_UNITS_FAILED,
  TOKEN_EXPIRED,
  TOKEN_CHECK_REQUESTED,
  AXIOS_TIMEOUT,
} from '../actions/types.action';

export default (state = INITIAL_STATE.errors, action) => {
  switch (action.type) {
    case LOGIN_FAILED:
      return Object.assign({}, state, {login: action.message});
    case CONFIRM_ORDER_FAILED:
      return Object.assign({}, state, {confirmOrder: action.message});
    case CONFIRM_ORDER_REQUESTED:
      return Object.assign({}, state, {confirmOrder: null});
    case BOOKING_AREAS_FAILED:
      return Object.assign({}, state, {bookingAreas: action.message});
    case BOOKING_UNITS_FAILED:
      return Object.assign({}, state, {bookingUnits: action.message});
    case BOOKING_DETAILS_FAILED:
      return Object.assign({}, state, {bookingDetails: action.message});
    case BOOKING_DETAILS_REQUESTED:
      return Object.assign({}, state, {bookingDetails: null});
    case TIME_SLOTS_FAILED:
      return Object.assign({}, state, {timeSlots: action.message});
    case TIME_SLOTS_REQUESTED:
      return Object.assign({}, state, {timeSlots: null});
    case ADD_PO_FAILED:
      return Object.assign({}, state, {addPo: action.message});
    case ADD_PO_REQUESTED:
      return Object.assign({}, state, {addPo: null});
    case REMOVE_PO_FAILED:
      return Object.assign({}, state, {removePo: action.message});
    case REMOVE_PO_REQUESTED:
      return Object.assign({}, state, {removePo: null});
    case UPDATE_BOOKING_FAILED:
      return Object.assign({}, state, {updateBooking: action.message});
    case UPDATE_BOOKING_REQUESTED:
      return Object.assign({}, state, {updateBooking: null});
    case UPDATE_DELIVERY_FAILED:
      return Object.assign({}, state, {updateDelivery: action.message});
    case UPDATE_DELIVERY_REQUESTED:
      return Object.assign({}, state, {updateDelivery: null});
    case TOKEN_EXPIRED:
      return Object.assign({}, state, {tokenExpired: action.message});
    case TOKEN_CHECK_REQUESTED:
      return Object.assign({}, state, {tokenExpired: null});
    case AXIOS_TIMEOUT:
      return Object.assign({}, state, {timeOut: action.message});
    case CLEAR_ERRORS:
      return null;
    default:
      return state;
  }
};
