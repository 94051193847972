import React from 'react';
import {Title, Text, SubTitle, Wrapper} from './BrowserNotSupported.style';

export const BrowserNotSupported = () => (
  <Wrapper>
    <Title>Sorry...</Title>
    <SubTitle>Internet Explorer is no longer supported.</SubTitle>
    <Text>You will need to use an up-to-date browser to view our website.</Text>
    <Text>Chrome, Safari and Firefox are good choices!</Text>
  </Wrapper>
);
