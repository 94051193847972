import {put, call, takeLatest} from 'redux-saga/effects';
import * as ActionTypes from '../actions/types.action';
import {confirmPurchaseOrder} from '../api/confirmOrder.api';
import {getPurchaseOrder} from '../api/auth.api';
import {ERRORS} from '../constants/constants';

export function* purchaseOrder(purchId, purchPin, tokenString) {
  return yield call(getPurchaseOrder, purchId, purchPin, tokenString);
}

function* confirmPurchOrder(purchId, purchPin, deliveryDate, values, token) {
  return yield call(
    confirmPurchaseOrder,
    purchId,
    purchPin,
    deliveryDate,
    values,
    token
  );
}

function* confirmOrder(action) {
  const {purchId, purchPin, deliveryDate, values, token} = action;
  try {
    yield* confirmPurchOrder(purchId, purchPin, deliveryDate, values, token);
    yield put({
      type: ActionTypes.CONFIRM_ORDER_SUCCESS,
    });
    yield put({
      type: ActionTypes.PURCHASE_ORDER_REQUESTED,
      purchId,
      purchPin,
      token,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.CONFIRM_ORDER_FAILED,
      message: e.response?.data?.Error || ERRORS.CONFIRM_PURCHASE_ORDER_ERROR,
    });
  } finally {
    yield put({
      type: ActionTypes.TOGGLE_PO_CONFIRMED_MODAL,
    });
  }
}

function* getPurchOrder(action) {
  const {purchId, purchPin, token} = action;
  try {
    const poData = yield call(purchaseOrder, purchId, purchPin, token);
    yield put({
      type: ActionTypes.PURCHASE_ORDER_SUCCESS,
      poData,
    });
    yield put({
      type: ActionTypes.NAVIGATE_TO_ROUTE,
      nextRoute: `/dashboard`,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.PURCHASE_ORDER_FAILED,
      message: e.response?.data?.Error || ERRORS.GET_PURCHASE_ORDER_ERROR,
    });
  }
}

export default function* purchaseOrderYield() {
  yield takeLatest(ActionTypes.CONFIRM_ORDER_REQUESTED, confirmOrder);
  yield takeLatest(ActionTypes.PURCHASE_ORDER_REQUESTED, getPurchOrder);
}
