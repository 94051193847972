import * as ActionTypes from './types.action';

export const getBookingDetails = (purchId, purchPin, token) => ({
  type: ActionTypes.BOOKING_DETAILS_REQUESTED,
  purchId,
  purchPin,
  token,
});

export const refreshBookingDetails = (purchId, bookingId, token) => ({
  type: ActionTypes.REFRESH_BOOKING_DETAILS,
  purchId,
  bookingId,
  token,
});

export const getBookingAreas = (purchId, token) => ({
  type: ActionTypes.BOOKING_AREAS_REQUESTED,
  purchId,
  token,
});

export const getBookingUnits = (purchId, token) => ({
  type: ActionTypes.BOOKING_UNITS_REQUESTED,
  purchId,
  token,
});

export const getBookingSlots = (purchId, deliveryId, token) => ({
  type: ActionTypes.TIME_SLOTS_REQUESTED,
  purchId,
  deliveryId,
  token,
});

export const addNewPo = (
  purchId,
  purchIdFieldValue,
  purchPinFieldValue,
  bookingId,
  token
) => ({
  type: ActionTypes.ADD_PO_REQUESTED,
  purchId,
  purchIdFieldValue,
  purchPinFieldValue,
  bookingId,
  token,
});

export const removePo = (
  purchId,
  purchIdFieldValue,
  purchPinFieldValue,
  bookingId,
  token
) => ({
  type: ActionTypes.REMOVE_PO_REQUESTED,
  purchId,
  purchIdFieldValue,
  purchPinFieldValue,
  bookingId,
  token,
});

export const addNewDelivery = (
  purchId,
  bookingId,
  purchIdList,
  deliveryUnits,
  token
) => ({
  type: ActionTypes.ADD_DELIVERY_REQUESTED,
  bookingId,
  purchIdList,
  deliveryUnits,
  purchId,
  token,
});

export const updateDelivery = params => ({
  type: ActionTypes.UPDATE_DELIVERY_REQUESTED,
  params,
});

export const submitDelivery = params => ({
  type: ActionTypes.SUBMIT_DELIVERY_REQUESTED,
  params,
});
