import React, {useEffect, useState} from 'react';
import {useParams, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ErrorIcon from '../../assets/errorIcon.png';

import {clearDetailsDispatch, logInWithPO} from '../../actions/auth.action';
import Body from './Body';
import Loading from '../../components/loading/Loading';
import {resetNextRoute} from '../../actions/nextRoute.action';
import {clearErrors} from '../../actions/errors.action';
import {
  Block,
  ButtonContainer,
  Container,
  Error,
  ErrorContainer,
  Field,
  FieldErrorText,
  FormContainer,
  FormWrapper,
  Header,
  HeaderContainer,
  Label,
  LoadingWrapper,
  SubHeader,
  SubmitButton,
} from './Login.style';
import FraudBody from '../../components/Fraud/FraudBody';

function LoginForm({
  logInWithPO,
  history,
  errors,
  isLoading,
  nextRoute,
  clearErrors,
  navToFraudAwareness,
  navToFraudWarning,
  navToSuppliersNotification,
  clearDetailsDispatch,
}) {
  const {idParam, pinParam} = useParams();
  const [redirect, setRedirect] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [purchNumField, setPurchNumField] = useState(idParam || '');
  const [purchNumError, setPurchNumError] = useState(false);
  const [pinNumField, setPinNumField] = useState(pinParam || '');
  const [pinNumError, setPinNumError] = useState(false);
  const loginError = errors && errors.login;

  useEffect(() => {
    clearDetailsDispatch();
  }, [clearDetailsDispatch]);

  useEffect(() => {
    clearErrors();
    setRedirect(false);
  }, [clearErrors]);

  useEffect(() => {
    if (nextRoute && redirect) {
      history.push(nextRoute);
    }
  }, [history, nextRoute, redirect]);

  useEffect(() => {
    !isLoading && isSubmitting && setIsSubmitting(false);
  }, [isLoading, isSubmitting]);

  function handleSubmit() {
    if (!purchNumField) setPurchNumError(true);
    else setPurchNumError(false);
    if (!pinNumField) setPinNumError(true);
    else setPinNumError(false);
    if (purchNumField && pinNumField) {
      setIsSubmitting(true);
      logInWithPO(purchNumField.trim(), pinNumField.trim());
      setRedirect(true);
      clearFields();
    }
  }

  const clearFields = () => {
    setPurchNumField('');
    setPinNumField('');
  };

  function handlePurchNumChange(e) {
    setPurchNumField(e.target.value);
  }

  function handlePinNumChange(e) {
    setPinNumField(e.target.value);
  }

  const RenderSubmitButton = props => {
    return <SubmitButton {...props}>SUBMIT</SubmitButton>;
  };

  const renderLoadingSpinner = (
    <LoadingWrapper>
      <Loading isLight={false} />
    </LoadingWrapper>
  );

  const renderHeader = (
    <HeaderContainer>
      <Header>Sign in</Header>
      <SubHeader>Use your purchase order number and pin</SubHeader>
    </HeaderContainer>
  );

  const renderFields = (
    <FormContainer>
      <Block>
        <Label error={loginError || purchNumError}>Order Number</Label>
        <Field
          type="text"
          name="purchNum"
          label="Order Number"
          value={purchNumField}
          error={loginError || purchNumError}
          onChange={e => handlePurchNumChange(e)}
        />
      </Block>
      <FieldErrorText>{purchNumError && 'Required'}</FieldErrorText>
      <Block>
        <Label error={loginError || pinNumError}>PIN</Label>
        <Field
          type="text"
          name="pin"
          label="PIN"
          value={pinNumField}
          error={loginError || pinNumError}
          onChange={e => handlePinNumChange(e)}
        />
      </Block>
      <FieldErrorText>{pinNumError && 'Required'}</FieldErrorText>
    </FormContainer>
  );

  const renderSubmitButton = (
    <ButtonContainer>
      <RenderSubmitButton
        type="button"
        onClick={handleSubmit}
        disabled={isSubmitting}
      />
    </ButtonContainer>
  );

  const renderLogInForm = (
    <FormWrapper>
      {isSubmitting ? renderLoadingSpinner : renderFields}
    </FormWrapper>
  );

  const renderError = errors && errors.login && !isLoading && (
    <ErrorContainer>
      <img src={ErrorIcon} alt="React Logo" />
      <Error>Invalid Order Number or Pin</Error>
    </ErrorContainer>
  );

  return (
    <div>
      <Body />
      <Container>
        {renderHeader}
        {renderLogInForm}
        {renderSubmitButton}
      </Container>
      {renderError}
      <FraudBody
        navToFraudAwareness={navToFraudAwareness}
        navToFraudWarning={navToFraudWarning}
        navToSuppliersNotification={navToSuppliersNotification}
      />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    purchId: state.auth.PurchId,
    token: state.auth.Token,
    errors: state.errors,
    isLoading: state.loading.login,
    nextRoute: state.nextRoute,
  };
};

const mapDispatchToProps = dispatch => ({
  logInWithPO: bindActionCreators(logInWithPO, dispatch),
  clearErrors: bindActionCreators(clearErrors, dispatch),
  clearDetailsDispatch: bindActionCreators(clearDetailsDispatch, dispatch),
  resetNextRoute: bindActionCreators(resetNextRoute, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginForm));
