import styled from 'styled-components';
import {media} from '../../styles/media-queries';
import theme from '../../styles/theme';
import {Button3b} from '../../styles/components/buttons';
import {
  MainHeader,
  SubHeaderSm,
  DescriptiveText,
} from '../../styles/generic.style';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 2rem 3rem 5rem 3rem;
  position: relative;
  z-index: 10;
  border: solid 3px ${theme.borderDarkColor};
  margin-top: 3rem;
  ${media.small`
    height: 75rem;
  `};
  ${media.smaller`
    height: 85rem;
  `};
`;

export const Header = styled(MainHeader)`
  text-align: Left;
  padding: 2rem 0;
  font-size: 2.2rem;
`;

export const SubHeader = styled(SubHeaderSm)`
  width: 100%;
`;

export const MainText = styled(DescriptiveText)`
  padding: 0;
`;

export const BackButton = styled(Button3b)`
  width: 12rem;
  height: 3rem;
  margin-top: 2rem;
  align-items: center;
  text-align: center;
  &:hover {
    svg {
      fill: ${p => p.theme.secondaryBgColor};
    }
  }
  ${media.smaller`
    width: ${p => (p.fullWidth ? '100%' : '15rem')};
  `};
  ${media.smallest`
    text-align: center;
    height: auto;
  `};
`;
