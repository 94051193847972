import styled from 'styled-components';
import {media} from '../styles/media-queries';

export const Container = styled.div`
  width: 100%;
  ${media.medium`
    max-width: 100%;
    overflow-x: hidden;
  `};
`;

export const StyledApp = styled.div`
  position: relative;
  width: 100rem;
  max-width: 100rem;
  margin: 0 auto;
  justify-content: center;
  z-index: 1;
  ${media.medium`
    width: 100%;
    padding: 0 1rem;
  `};
  ${media.small`
    width: 100%;
  `};
`;

export const Wrapper = styled.div`
  position: relative;
`;
