import {DELIVERY_UNITS, SIZES} from '../constants/constants';

export default class BookingPageHelper {
  static newDeliveryBuilder = bookingOrdersField => {
    const purchIdList = bookingOrdersField.map(po => ({PurchId: po.PurchId}));
    const deliveryUnits = [
      {
        Qty: 0,
        StoreAreaId: '',
        UnitId: '',
      },
    ];
    return [purchIdList, deliveryUnits];
  };

  static deliveryUnitsBuilder = values => {
    const deliveryData = [];
    values.forEach(value => {
      if (Number.parseInt(value.qty)) {
        deliveryData.push({
          [DELIVERY_UNITS.QTY]: value['qty'],
          [DELIVERY_UNITS.STORE_AREA_ID]: value.areaId,
          [DELIVERY_UNITS.UNIT_ID]: value.unit,
        });
      }
    });
    return deliveryData;
  };

  static deliveryOrdersBuilder = purchIds => {
    return purchIds.map(po => ({PurchId: po.poNum}));
  };

  static createFormInitialValues = (
    transportCompany,
    transportContactName,
    transportContactNum,
    transportEmail,
    vehicleReg
  ) => {
    const initialValues = {
      transportCompany: transportCompany || '',
      transportContactName: transportContactName || '',
      transportContactNum: transportContactNum || '',
      transportEmail: transportEmail || '',
      vehicleReg: vehicleReg || '',
    };
    return initialValues;
  };

  static initQuantityData(bookingAreasData, deliveryUnits) {
    const initialValues = [];
    if (bookingAreasData) {
      for (let key in bookingAreasData) {
        let name = bookingAreasData[key].Name;
        let areaId = bookingAreasData[key]['WMSStoreArea'];
        let qty, unit;
        deliveryUnits.forEach(area => {
          if (area['StoreAreaId'] === areaId) {
            qty = area['Qty'];
            unit = area['UnitId'];
          }
        });
        initialValues.push({
          name,
          areaId,
          qty: qty || '',
          unit: unit || 'None',
          qtyError: false,
          unitError: false,
        });
      }
    }
    return initialValues;
  }

  static createPoList = (purchId, bookingOrdersField) => {
    let poList = [
      {
        poNum: purchId,
        poPin: '',
        isActive: false,
        error: false,
        isSubmitting: false,
      },
    ];
    for (let key in bookingOrdersField) {
      if (bookingOrdersField[key].PurchId !== purchId) {
        poList.push({
          poNum: bookingOrdersField[key].PurchId,
          poPin: '',
          isActive: false,
          error: false,
          isSubmitting: false,
        });
      }
    }
    return poList;
  };

  static qtyFormErrorChecker = values => {
    let qtyData = [...values];
    let isError = false;
    qtyData.forEach(data => {
      if (parseInt(data.qty) === 0) {
        data.qtyError = true;
        isError = true;
      }
      if (parseInt(data.qty) > 0 && data.unit === 'None') {
        data.unitError = true;
        isError = true;
      }
      if (isNaN(parseInt(data.qty)) && data.unit !== 'None') {
        data.qtyError = true;
        isError = true;
      }
    });
    return [qtyData, isError];
  };

  static disableSlotsButton = values => {
    let isDisabled = true;
    if (!values) return isDisabled;
    Object.keys(values).some(index => {
      if (parseInt(values[index].qty) > 0 && values[index].unit !== 'None') {
        return (isDisabled = false);
      }
    });
    return isDisabled;
  };

  static bookingDetailsConstants = {
    GET_BOOKING_ID: 'GET_BOOKING_ID',
    GET_DELIVERY_DATE: 'GET_DELIVERY_DATE',
    GET_DELIVERY_ADDRESS: 'GET_DELIVERY_ADDRESS',
  };

  static bookingDetails = [
    {
      name: 'Booking Number',
      command: this.bookingDetailsConstants.GET_BOOKING_ID,
    },
    {
      name: 'Delivery Date',
      command: this.bookingDetailsConstants.GET_DELIVERY_DATE,
    },
    {
      name: 'Delivery address',
      command: this.bookingDetailsConstants.GET_DELIVERY_ADDRESS,
    },
  ];

  static transportFormDetails = [
    {
      name: 'transportCompany',
      label: 'Company name',
    },
    {
      name: 'transportContactName',
      label: 'Contact Name',
    },
    {
      name: 'transportContactNum',
      label: 'Contact Number',
    },
    {
      name: 'transportEmail',
      label: 'Email Address',
    },
    {
      name: 'vehicleReg',
      label: 'License Plate',
    },
  ];
}
