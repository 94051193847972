import {INITIAL_STATE} from './initial.state';
import {
  BOOKING_DETAILS_SUCCESS,
  BOOKING_UNITS_SUCCESS,
  TIME_SLOTS_SUCCESS,
  BOOKING_AREAS_SUCCESS,
  CLEAR_TIME_SLOTS,
} from '../actions/types.action';

export default (state = INITIAL_STATE.booking, action) => {
  switch (action.type) {
    case BOOKING_DETAILS_SUCCESS:
      return Object.assign({}, state, {bookingDetails: action.details});
    case BOOKING_AREAS_SUCCESS:
      return Object.assign({}, state, {bookingAreas: action.areas});
    case BOOKING_UNITS_SUCCESS:
      return Object.assign({}, state, {bookingUnits: action.units});
    case TIME_SLOTS_SUCCESS:
      return Object.assign({}, state, {timeSlots: action.slots});
    case CLEAR_TIME_SLOTS:
      return Object.assign({}, state, {timeSlots: null});
    default:
      return state;
  }
};
